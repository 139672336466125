import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_JSON_MODAL_STATE } from 'src/reduxActions/actionNameEnums';
import ReactJson from 'react-json-view';

const ShowJSONModal: React.FC = () => {
  const { jsonModalVisibility, jsonModalValue } = useSelector(
    (state: any) => state.config,
  );
  const dispatch = useDispatch();
  const onCancel = () =>
    dispatch({
      type: CHANGE_JSON_MODAL_STATE,
      payload: false,
    });

  let updatedJSONModalValue = jsonModalValue;
  if (!jsonModalValue) {
    updatedJSONModalValue = '{}';
  }

  return (
    <>
      <Modal
        title="JSON"
        centered
        visible={jsonModalVisibility}
        onCancel={onCancel}
        width={800}
        footer={null}
      >
        <ReactJson
          style={{ minHeight: '200px', padding: '1em', overflow: 'auto' }}
          theme="monokai"
          src={JSON.parse(updatedJSONModalValue)}
        />
      </Modal>
    </>
  );
};

export default ShowJSONModal;
