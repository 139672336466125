import { Radio, Switch, Tooltip, Checkbox } from 'antd';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import FiltersMap from 'src/components/common/FilterComponent';
import moment from 'moment';
import { dashboardFilterTypes, filterType } from 'src/constants';
import SimpleFilterMap from 'src/components/common/SimpleFilterComponent';

const useStyle = createUseStyles({
    basePane: {
      width: '33rem',
      padding: '10px',
      display: 'flex',
    },
    boolFilterContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    freeze:{
        backgroundColor: '#FDF5DB',
        height: '46px',
        display: 'flex',
        alignItems: 'center',
    },
    filterWrapper: {
      display: 'flex',
      '& .ant-radio-button-wrapper ': {
        border: 'none',
        borderRight: '1px solid rgba(0, 0, 0, 0.06)',
        width:'100%',
      },
      '& .ant-radio-button-checked ': {
        background: '#e6f7ff',
      },
    },
    filterCompWrapper: {
      width:'100%',
    },
    betweenContainer: {
      display: 'flex',
      columnGap: '1rem',
    },
});

const GeneralFilter = (props) => {
  const classes = useStyle();
  const {
    metricsName,
    type,
    filterMetaData,
    filterListRadio,
    onChangeRadio,
    onChangeFilterData,
    onChangeBoolData,
    onChangeFilterDataForSimple,
    dashboardFilterType,
    filterData,
    showfreezeOption,
    searchOptions,
    selectedFilterReportType,
    checkedValues,
    onReset,
    visiblePopOver,
    prettyName = '',
  } = props;
  const selectedValue = filterData?.[metricsName];
  const [selectedDateRange, setSelectedDateRange] = useState<any>();
  const filterMatched = filterListRadio.find(
    (filter) => filter.name === selectedValue.selectedFilter,
  );
  const showTime = {
    defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
  };
  const getDateFormat = (filterName) => {
    return filterName !== 'timestampzIsOn' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
  };
  const getDefaultValue = (filterName) => {
    switch (filterName) {
      case 'timestampzIsAfter':
        return moment().startOf('day').format('YYYY-MM-DD HH:mm');
      case 'timestampzIsBefore':
        return moment().endOf('day').format('YYYY-MM-DD HH:mm');
      case 'timestampzIsOn':
        return moment().format('YYYY-MM-DD');
      case 'timestampzCurrent':
        return 'day';
      default:
        return null;
    }
  };

  const getWidth = () => {
    switch (type) {
      case 'number':
        return '35%';
      case 'text':
        return '50%';
      case 'json':
        return '40%';
      default:
        return '28%';
    }
  };

  const onChange = (checkedValues: any) => {
    let selectedFilter;
    let data;
    switch (checkedValues?.length) {
      case 1:
        selectedFilter =
          checkedValues[0] === 'true' || checkedValues[0] === 'false'
            ? 'booleanIsEqualTo'
            : 'booleanIsNull';
        data =
          checkedValues[0] === 'true'
            ? true
            : checkedValues[0] === 'false'
            ? false
            : undefined;
        break;
      case 2:
        selectedFilter =
          checkedValues[0] === 'true'
            ? checkedValues[1] === 'false'
              ? 'booleanIsNotNull'
              : 'booleanIsNotFalse'
            : 'booleanIsNotTrue';
        break;
      case 3:
        selectedFilter = 'doNotApplyThisFilter';
    }
    onChangeBoolData(checkedValues, selectedFilter, data);
  };

  if (type === filterType.BOOLEAN) {
    const options = [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
      { label: 'Null', value: 'null' },
    ];
    return (
      <div>
        <Checkbox.Group
          className={classes.boolFilterContainer}
          options={options}
          value={checkedValues[metricsName]}
          onChange={onChange}
        ></Checkbox.Group>
      </div>
    );
  }

  const FilterComp = searchOptions
    ? selectedValue?.filterInputType === 'inputOrMultiSelect'
      ? FiltersMap.searchableSelect
      : FiltersMap[selectedValue.filterInputType]
    : FiltersMap[selectedValue.filterInputType];

  if (dashboardFilterType === dashboardFilterTypes.SIMPLE) {
    const SimpleFilterComp = SimpleFilterMap[type];
    return (
      <SimpleFilterComp
        onChangeFilterDataForSimple={onChangeFilterDataForSimple}
        selectedValue={selectedValue}
        metricsName={metricsName}
        selectedFilterReportType={selectedFilterReportType}
        prettyName={prettyName}
        onChangeRadio={onChangeRadio}
        onChangeFilterData={onChangeFilterData}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
        visiblePopOver={visiblePopOver}
      />
    );
  }

  return (
    <div className = {classes.basePane}>
      <div style = {{width:getWidth()}}>
        <Radio.Group
          onChange={(e) => onChangeRadio(e.target.value)}
          value={
            selectedValue?.selectedFilter ||
            (type === 'timestampz' || type === 'timestamp' || type === 'date'
              ? 'timestampzBetween' && onChangeRadio('timestampzBetween')
              : type === 'number'
              ? 'numberIsGreaterThan' && onChangeRadio('numberIsGreaterThan')
              : type === 'text'
              ? 'textIsEqualTo' && onChangeRadio('textIsEqualTo')
              : type === 'json'
              ? 'textContains' && onChangeRadio('textContains')
              : null)
          }
        >
          {filterListRadio.map((filter) => {
            if (filter.name === 'doNotApplyThisFilter') {
              return null;
            }
            return (
              <div className={classes.filterWrapper} key={filter.name}>
                <Radio.Button
                  value={filter.name}
                >
                    <div>{filter.prettyName}</div>
                </Radio.Button>
                </div>
            );
          })}
        </Radio.Group>
      </div>
      <div className={classes.filterCompWrapper}>
        {selectedValue?.filterInputType !== 'noInput'&& FilterComp ? (
          <div className={classes.filterCompWrapper}>
            <FilterComp
              multipleSelect={
                searchOptions
                  ? selectedValue.filterInputType === 'inputOrMultiSelect'
                  : false
              }
              metricsName={metricsName}
              onChange={onChangeFilterData}
              value={selectedValue?.data}
              type={type}
              data={filterMetaData?.data || filterMatched?.data}
              selectedFilterReportType={selectedFilterReportType}
              prettyName={prettyName}
              style={{ width: '100%' }}
              betweenContainer={classes.betweenContainer}
              dateFormat={getDateFormat(selectedValue?.selectedFilter)}
              boolShowTime={selectedValue?.selectedFilter !== 'timestampzIsOn'}
              quickSelection={true}
              showTime={showTime}
              defaultValue={getDefaultValue(selectedValue?.selectedFilter)}
              onReset={onReset}
              isEditing={false}
            />
          </div>
        ) : null}
      </div>
      {showfreezeOption ? (
        <div className={classes.freeze}>
          <Switch size={'small'} style={{ margin: '0px 6px 0px 12px' }} />
          <Tooltip
            title={'Global filters on dashboard will not override this filter'}
          >
            <span>Freeze Filter Value</span>
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};

export default GeneralFilter;
