import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import filtersMap from 'src/components/common/FilterComponent';
import lodashValues from 'lodash/values';
import {
  FORMATTING_CONDITION_DATA_CHANGE,
  FORMATTING_CONDITION_COLUMN_CHANGE,
  FORMATTING_CONDITION_OPERATOR_CHANGE,
} from 'src/reduxActions/actionNameEnums';
import { useStyles } from './styles';
import { Select } from 'antd';
import lodashCloneDeep from 'lodash/cloneDeep';

const ListOptions = (props) => {
  const classes = useStyles();
  const { options, onChange, title, style, ...rest } = props;

  return (
    <div>
      <Select
        style={{ width: '100%', display: 'block', ...style }}
        onChange={onChange}
        options={options}
        className={classes.option}
        {...rest}
      />
    </div>
  );
};

const ConditionForm = (props) => {
  const { rules, index, ruleIndex } = props;
  const rule = rules[ruleIndex].rules[index];
  const { conditions } = rule;
  const dispatch = useDispatch();
  const classes = useStyles();
  const columnsMap = useSelector((state: any) => state.metrics);
  const columns = lodashValues(columnsMap);
  const supportedFilters = useSelector(
    (state: any) => state?.masterData?.supportedFilters,
  );
  const selectedReport = useSelector(
    (state: any) => state?.reportMetaData?.selectedReport,
  );

  const searchOptions = false;

  const getFilterType = (columnMetaData) => {
    if (columnMetaData?.type === 'text' || columnMetaData?.type === 'json') {
      if (['min', 'max'].includes(columnMetaData.operation)) {
        return columnMetaData.type;
      }
      return 'number';
    }

    if (columnMetaData?.type === 'boolean') {
      if (
        ['count_true', 'count_false', 'count'].includes(
          columnMetaData.operation,
        )
      ) {
        return 'number';
      }
      return 'boolean';
    }

    return columnMetaData?.type || 'number';
  };

  const onConditionColumnChange = (value, index) => {
    const columnMetaData = columnsMap[value.value];
    const filterType = getFilterType(columnMetaData);
    const filterListRadio = supportedFilters[filterType] || [];
    const operatorOptions = filterListRadio.map((option) => {
      return {
        ...option,
        key: option.name,
        value: option.name,
        label: option.prettyName,
      };
    });

    const defaultOption = operatorOptions.find(
      (option) => option.label === 'Is not null',
    );
    const rulesCopy = [...rules];
    rulesCopy[ruleIndex].rules[index] = {
      ...rulesCopy[ruleIndex].rules[index],
      conditions: [
        {
          ...rulesCopy[ruleIndex].rules[index].conditions[0],
          columns: [value.value],
          operator: defaultOption
            ? defaultOption.value
            : operatorOptions[0].value,
          value: '{}',
        },
      ],
    };

    dispatch({
      type: FORMATTING_CONDITION_COLUMN_CHANGE,
      payload: rulesCopy,
    });
  };

  const onConditionOperatorChange = (value, index) => {
    const rulesCopy = lodashCloneDeep(rules);
    rulesCopy[ruleIndex].rules[index] = {
      ...rulesCopy[ruleIndex].rules[index],
      conditions: [
        {
          ...rulesCopy[ruleIndex].rules[index].conditions[0],
          operator: value.name,
          value: '{}',
        },
      ],
    };

    dispatch({
      type: FORMATTING_CONDITION_OPERATOR_CHANGE,
      payload: rulesCopy,
    });
  };

  const onChangeConditionData = (value, index) => {
    const rulesCopy = lodashCloneDeep(rules);
    rulesCopy[ruleIndex].rules[index] = {
      ...rulesCopy[ruleIndex].rules[index],
      conditions: [
        {
          ...rulesCopy[ruleIndex].rules[index].conditions[0],
          value: JSON.stringify({ value }),
        },
      ],
    };

    dispatch({
      type: FORMATTING_CONDITION_DATA_CHANGE,
      payload: rulesCopy,
    });
  };

  const conditionColumns = columns
    .map((col) => {
      if (col.type === 'text' && !col.operation) return null;
      return {
        ...col,
        label: col.prettyName,
      };
    })
    .filter((condition) => condition);

  return (
    <div style={{ width: '40%' }}>
      {conditions?.map((condition) => {
        const column = condition?.columns?.[0];
        const columnMetaData = columnsMap[column];
        const filterType = getFilterType(columnMetaData);
        const filterListRadio = supportedFilters[filterType] || [];
        const operatorOptions = filterListRadio.map((option) => {
          return {
            ...option,
            key: option.name,
            value: option.name,
            label: option.prettyName,
          };
        });

        const ignoreFilterOptionIdx = filterListRadio.findIndex(
          (item) => item.name === 'ignoreFilter',
        );
        if (ignoreFilterOptionIdx) {
          operatorOptions.splice(ignoreFilterOptionIdx, 1);
        }

        const operator = condition?.operator;
        const selectedRadioMetaData = filterListRadio.find(
          (filter) => filter.name === operator,
        );
        const defaultSelection = filterListRadio[0];

        const currentFilter = selectedRadioMetaData || defaultSelection;
        const FilterComp = searchOptions
          ? currentFilter.type === 'inputOrMultiSelect'
            ? filtersMap.searchableSelect
            : filtersMap[currentFilter.type]
          : filtersMap[currentFilter.type];
        return (
          <div className={classes.conditionContainer}>
            <div className={classes.conditionTitle}>
              {index === 0 ? 'If' : 'Else If'}
            </div>
            <div className={classes.conditionRow}>
              <div style={{ flex: 1, display: 'flex' }}>
                <p className={classes.fieldName} style={{ flex: 0.3 }}>
                  Column
                </p>
                <div style={{ flex: 0.7 }}>
                  <ListOptions
                    options={conditionColumns}
                    value={condition.columns[0]}
                    onChange={(_, option) =>
                      onConditionColumnChange(option, index)
                    }
                  />
                </div>
              </div>
              <div style={{ flex: 1, display: 'flex' }}>
                <p className={classes.fieldName} style={{ flex: 0.3 }}>
                  Operator
                </p>
                <div style={{ flex: 0.7 }}>
                  <ListOptions
                    options={operatorOptions}
                    value={condition.operator}
                    onChange={(_, option) =>
                      onConditionOperatorChange(option, index)
                    }
                  />
                </div>
              </div>
              <div style={{ flex: 1, display: 'flex' }}>
                {currentFilter.type !== 'noInput' ? (
                  <>
                    <p
                      className={classes.fieldName}
                      style={{
                        flex: 0.3,
                      }}
                    >
                      Value
                    </p>
                    <div style={{ flex: 0.7 }}>
                      <FilterComp
                        multipleSelect={
                          searchOptions
                            ? currentFilter.type === 'inputOrMultiSelect'
                            : false
                        }
                        metricsName={column}
                        onChange={(val) => onChangeConditionData(val, index)}
                        value={JSON.parse(condition.value || '{}')}
                        size="middle"
                        style={{ width: '100%' }}
                        type={filterType}
                        data={currentFilter?.data}
                        selectedFilterReportType={selectedReport}
                        prettyName={columnMetaData?.prettyName || column}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ConditionForm;
