import Icon, { LeftOutlined, PlusOutlined, RightOutlined, ImportOutlined } from '@ant-design/icons';
import { Menu, Button } from 'antd';
import React, { useState } from 'react';
import CustomDivider from './CustomDivider';
import { ReactComponent as ReportsSVG } from './../../assets/Reports.svg';
import { ReactComponent as HomeSVG } from './../../assets/Home.svg';
import { ReactComponent as DashboardSVG } from './../../assets/Dashboard.svg';
import { ReactComponent as MetricsSVG } from './../../assets/Metrics.svg';
import { ReactComponent as AlertsSVG } from './../../assets/Alerts.svg';
import { ReactComponent as ScheduledEmails } from './../../assets/ScheduledEmails.svg';
import { useHistory, useLocation } from 'react-router-dom';

import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import {
  CHANGE_SELECTED_DASHBOARD_TYPE,
  CHANGE_LEFT_PANE_STATE,
  CHANGE_NAV_TAB,
  CHANGE_SELECTED_REPORT_TYPE,
} from 'src/reduxActions/actionNameEnums';
import { getPermissionType } from '../componentHelper';
import {getProjectName} from '../common/utils/utils';
import { projectNames, permissionTypes } from 'src/constants';
import ImportModal from './ImportModal';

const useStyle = createUseStyles({
  menuItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    paddingRight: '8px',
    lineHeight: '22px',
    backgroundColor: '#FAFAFA',
  },
  createButton: {
    width: '80%',
    display: 'flex',
    color: 'white',
    justifyContent: 'center',
    marginBottom: '7%',
    alignItems: 'center',
    borderRadius: '0.2rem',
    border: 'none',
  },
  importButton: {
    display: 'flex',
    padding: '0 25%',
    justifyContent: 'center',
    marginBottom: '7%',
    alignItems: 'center',
    borderRadius: '0.2rem',
  },
  createButtonContainer: {
    width: '100%',
    marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  sideBarContainer: {
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    height: 'calc(100vh - 49px)',
    display: 'flex',
    flexShrink: 0,
    flexGrow: 0,
    justifyContent: 'center',
    flexBasis: '224px',
    transition: 'left 0.4s, position 0.4s',
    backgroundColor: '#FFFFFF',
  },
  sideBarDrawer: {
    backgroundColor: '#fff',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    clipPath: 'polygon(0 0, 100% 14%, 100% 84%, 0% 100%)',
    zIndex: 10,
    cursor: 'pointer',
    top: '50%',
    left: '100%',
    height: '66px',
    width: '14px',
  },
});

const Sider = (props) => {
  const projectName = getProjectName();
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const userType = useSelector((state:any) => state?.masterData?.userConfig?.typeOfUser);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const enableIncidentAlert = useSelector((state:any) => state?.masterData?.organisationConfig?.enableIncidentAlert);
  let permissionType = 'user';
  if(userType !== undefined){
    permissionType = getPermissionType(userType);
  }
  const landingDashboardId = useSelector(
    (state: any) => state.config.recievedLandingPageId,
  );
  const isDrawerOpen = useSelector((state: any) => state.config.leftPaneOpen);
  const currentSelectedNavTab = useSelector(
    (state: any) => state.config.currentSelectedNavTab,
  );
  const locationPathname = location.pathname.split('/');
  const currentLocationKey = locationPathname?.[1] ?? currentSelectedNavTab;

  const onChangeNavTab = (selectedNavTabs) => {
    dispatch({
      type: CHANGE_NAV_TAB,
      payload: selectedNavTabs,
    });
  };
  const onChangeDasboardType = (type) => {
    dispatch({
      type: CHANGE_SELECTED_DASHBOARD_TYPE,
      payload: type,
    });
  };
  const onChangeReportType = (type) => {
    dispatch({
      type: CHANGE_SELECTED_REPORT_TYPE,
      payload: type,
    });
  };
  const handleDashboardClick = (type) => {
    onChangeNavTab(['dashboards', `dashboards_${type}`]);
    onChangeDasboardType(type);
    history.push('/dashboard');
  };
  const handleReportClick = (type) => {
    onChangeNavTab(['reports', `reports_${type}`]);
    onChangeReportType(type);
    history.push('/reports');
  };
  const handleHomeClick = () => {
    if (landingDashboardId) {
      history.push(`/dashboard/${landingDashboardId}`);
    } else {
      history.push('/dashboard');
    }
    onChangeNavTab(['home']);
  };

  const handleRouteClick = (routeId, tabId = routeId) => {
    history.push(`/${routeId}`);
    onChangeNavTab([tabId]);
  };

  return (
    <div
      className={classes.sideBarContainer}
      style={{
        left: isDrawerOpen ? 0 : '-176px',
        position: isDrawerOpen ? 'relative' : 'absolute',
      }}
    >
      <div className={classes.createButtonContainer}>
      { permissionType  === permissionTypes.USER ? <></> :
      <>
        <Button
          type="primary"
          className={classes.createButton}
          onClick={() => history.replace('/visualize')}
          icon={<PlusOutlined />}
        >
          Create New
        </Button>
        <Button
          className={classes.importButton}
          onClick={() => setImportModalOpen(true)}
          icon={<ImportOutlined />}
        >
          Import
        </Button>
      </>
      }
      <ImportModal open={importModalOpen} setOpen={setImportModalOpen}/>
        <div
            onClick={() =>
            dispatch({
              type: CHANGE_LEFT_PANE_STATE,
              payload: !isDrawerOpen,
            })
          }
          className={classes.sideBarDrawer}
        >
          {isDrawerOpen ? (
            <LeftOutlined style={{ color: '#8C8C8C' }} />
          ) : (
            <RightOutlined style={{ color: '#8C8C8C' }} />
          )}
        </div>
        <CustomDivider />
        <div style={{ width: '100%' }}>
          <Menu mode="inline" selectedKeys={currentLocationKey}>
            <Menu.Item
              onClick={() => handleHomeClick()}
              key="home"
              icon={
                <Icon component={(iProps) => (<HomeSVG {...iProps} />)} />
              }
            >
              Home
            </Menu.Item>
            <Menu.SubMenu
              key="dashboards"
              title="Dashboards"
              icon={
                <Icon component={(iProps) => (<DashboardSVG {...iProps} />)} />
              }
            >
              { permissionType  === permissionTypes.USER ? <></> :
              <Menu.Item
                key={'dashboards_user'}
                onClick={() => handleDashboardClick('user')}
              >
                {' '}
                My Dashboards{' '}
              </Menu.Item>
              }
              <Menu.Item
                key={'dashboards_shared'}
                onClick={() => handleDashboardClick('shared')}
              >
                {' '}
                Shared with me{' '}
              </Menu.Item>
              { projectName === projectNames.LOGISTICS && permissionType !== permissionTypes.USER ? (
              <>
                <Menu.Item
                  key={'dashboards_shared_with_customer'}
                  onClick={() => handleDashboardClick('customerShared')}
                >
                  {' '}
                  Shared with customer{' '}
                </Menu.Item>
                <Menu.Item
                  key={'dashboards_shared_with_vendor'}
                  onClick={() => handleDashboardClick('vendorShared')}
                >
                  {' '}
                  Shared with vendor{' '}
                </Menu.Item>
              </> ) : null
              }
              { permissionType  === permissionTypes.USER ? <></> :
              <Menu.Item
                key={'dashboards_default'}
                onClick={() => handleDashboardClick('default')}
              >
                {' '}
                Default Library{' '}
              </Menu.Item>
              }
              { permissionType  === permissionTypes.USER ? <></> :
              <Menu.Item
                key={'dashboards_all'}
                onClick={() => handleDashboardClick('all')}
              >
                {' '}
                All Dashboards{' '}
              </Menu.Item>
              }
            </Menu.SubMenu>
            { permissionType  === permissionTypes.USER ? <></> :
            <Menu.SubMenu
              key="reports"
              title="Reports"
              icon={
                <Icon component={(iProps) => (<ReportsSVG {...iProps} />)} />
              }
            >
              <Menu.Item
                key={'reports_user'}
                onClick={() => handleReportClick('user')}
              >
                {' '}
                My Reports{' '}
              </Menu.Item>
              <Menu.Item
                key={'reports_shared'}
                onClick={() => handleReportClick('shared')}
              >
                {' '}
                Shared with me{' '}
              </Menu.Item>
              { projectName === projectNames.LOGISTICS || permissionType  !== permissionTypes.USER ? (
              <>
                <Menu.Item
                  key={'reports_shared_with_customer'}
                  onClick={() => handleReportClick('customerSharedReports')}
                >
                  {' '}
                  Shared with customer{' '}
                </Menu.Item>
                <Menu.Item
                  key={'reports_shared_with_vendor'}
                  onClick={() => handleReportClick('vendorSharedReports')}
                >
                  {' '}
                  Shared with vendor{' '}
                </Menu.Item>
              </>
              ): null
              }
              <Menu.Item
                key={'reports_default'}
                onClick={() => handleReportClick('default')}
              >
                {' '}
                Default Library{' '}
              </Menu.Item>
              <Menu.Item
                key={'reports_all'}
                onClick={() => handleReportClick('all')}
              >
                {' '}
                All Reports{' '}
              </Menu.Item>
            </Menu.SubMenu>
            }
            {permissionType !== permissionTypes.USER &&
            <Menu.Item
              onClick={() => handleRouteClick('scheduled-emails')}
              key="scheduled-emails"
              title={'Scheduled Emails'}
              icon={
                <Icon component={(iProps) => (<ScheduledEmails {...iProps} />)} />
              }
            >
              Scheduled Emails
            </Menu.Item>}
            {enableIncidentAlert && permissionType !== permissionTypes.USER ?
              <Menu.Item
                onClick={() => handleRouteClick('metrics')}
                key="metrics"
                title={'Metrics'}
                icon={
                  <Icon component={(iProps) => (<MetricsSVG {...iProps} />)} />
                }
              >
                Metrics
              </Menu.Item>
            : null}
            {enableIncidentAlert && permissionType !== permissionTypes.USER ?
              <Menu.Item
                onClick={() => handleRouteClick('alerts')}
                key="alerts"
                icon={
                  <Icon component={(iProps) => (<AlertsSVG {...iProps} />)} />
                }
              >
                Alerts
              </Menu.Item>
            : null}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Sider;
