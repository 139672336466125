import { Button, Col, Divider, Menu, Row, Select, Checkbox } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { createUseStyles } from 'react-jss';

import FiltersMap from 'src/components/common/FilterComponent';
import {
  DASHBOARD_FILTER_RADIO_SELECTED,
  DASHBOARD_FILTER_SELECTED_VALUE_CHANGED,
  DASHBOARD_FILTER_SELECTED_BOOL_VALUE_CHANGED,
  GENERIC_DASHBOARD_FILTER_DATA_RESET,
  DASHBOARD_FILTER_UPDATED,
} from 'src/reduxActions/actionNameEnums';

const useStyles = createUseStyles({
  menu: {
    maxHeight: '17rem',
    overflowY: 'scroll',
  },
  menuItem: {
    '& .ant-menu-title-content': {
      textOverflow: 'ellipsis',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  avatar: {
    height: '25px',
    width: '25px',
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: '0.75rem 0',
  },
  checkboxGroup: {
    gap: '0.5rem',
    flexDirection: 'column',
  },
  menuItemWrapper: {
    marginLeft: 'auto',
    color: 'white',
    fontSize: '0.7rem',
    fontWeight: 600,
    padding: '0.1rem 0.55rem',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50%',
  },
  contentContainer: {
    padding: '0.5rem 0.75rem',
    maxHeight: '17rem',
    overflowY: 'scroll',
  },
  actionsContainer: {
    display: 'flex',
    padding: '0.5rem',
    justifyContent: 'center',
    gap: '0.5rem',
  },
  betweenContainer: {
    display: 'flex',
    columnGap: '1rem',
  },
  boolFilterContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonDivider: {
    marginTop: '0.5rem',
    marginBottom: 0,
    height: '100%',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
});

const FilterMenu = (props) => {
  const {
    dashboard,
    dashboardId,
    setCheckedValues,
    filterColorInitialState,
    boolInitialState,
    onClose,
    checkedValues,
    setFilterColor,
    filterColor,
    filterApplied,
    setFilterApplied,
    isTabletOrMobile,
  } = props;

  const classes = useStyles();
  const { onApplyFilter, dashboardTab, dashboardGroupId, filters } = dashboard;

  const currentTabFilters = filters[dashboardTab];
  const [currentFilter, setCurrentFilter] = useState<any>(currentTabFilters[0]);
  const [onReset, setOnReset] = useState(true);
  const dispatch = useDispatch();

  const filtersMetaData = useSelector(
    (state: any) => state?.masterData?.supportedFilters,
  );
  const filterData = useSelector(
    (state: any) => state.dashboardFilter[dashboardTab],
  );

  const handleBoolChange = (key, checkedValues) => {
    setCheckedValues((prevState) => ({
      ...prevState,
      [key]: checkedValues,
    }));
  };

  const onChangeRadio = (metricName, filterType) => (value) => {
    let filter;
    if (filtersMetaData) {
      filter = filtersMetaData[filterType]?.find((obj) => obj.name === value);
    }
    const filterInputType = filter?.type;
    if (filterInputType === 'noInput') {
      setFilterApplied(false);
    }
    dispatch({
      type: DASHBOARD_FILTER_RADIO_SELECTED,
      payload: {
        metricName,
        selectedFilter: value,
        dashboardTab,
        filterInputType,
        setFilterApplied,
        filterType,
      },
    });
  };

  const onChangeFilterData = (metricName) => (data) => {
    setFilterApplied(false);
    dispatch({
      type: DASHBOARD_FILTER_SELECTED_VALUE_CHANGED,
      payload: {
        metricName,
        dashboardTab,
        data: {
          value: data,
        },
      },
    });
  };

  const onChangeBoolData =
    (metricName) =>
    (checkedValues: CheckboxValueType[], selectedFilter, data) => {
      setFilterApplied(false);
      handleBoolChange(metricName, checkedValues);
      dispatch({
        type: DASHBOARD_FILTER_SELECTED_BOOL_VALUE_CHANGED,
        payload: {
          metricName,
          selectedFilter,
          dashboardTab,
          data: {
            value: data,
          },
        },
      });
    };

  const getCachedDashboards = () => {
    let cachedDashboards: any = [];
    const localCachedDashboard = localStorage.getItem('activeDashboard');
    if (localCachedDashboard) {
      cachedDashboards = JSON.parse(localCachedDashboard);
    }
    return cachedDashboards;
  };

  //we update the dashboard tab filters in an existing dashboardGroup or adding new dashboardGroup
  const setLocalStorage = (appliedFilterData) => {
    appliedFilterData.forEach((filterData) => {
      const cachedDashboards = getCachedDashboards();
      const newTabFilters = {
        [filterData.metric]: {
          name: filterData.filter.name,
          data: filterData.filter.data,
          metric: filterData.metric,
          type: filterData.filter.type,
        },
      };
      const newLocalStorageChange = {
        dashboardGroupId,
        activeTabId: dashboardId,
        tabs: {
          [dashboardId]: {
            filters: newTabFilters,
          },
        },
      };
      const cachedCurrentDashboardIndex = cachedDashboards?.findIndex(
        (activeDashboard) =>
          activeDashboard.dashboardGroupId === dashboardGroupId,
      ); //searching if the dashboardGroup is already present in localstorage
      const cachedCurrentDashboard =
        cachedDashboards[cachedCurrentDashboardIndex];
      const updateLocalStorageChange = {
        ...cachedCurrentDashboard,
        dashboardGroupId: newLocalStorageChange.dashboardGroupId,
        activeTabId: newLocalStorageChange.activeTabId,
        tabs: {
          ...cachedCurrentDashboard?.tabs,
          [dashboardId]: {
            filters: {
              ...(cachedCurrentDashboard?.tabs[dashboardId]?.filters ?? {}),
              ...newTabFilters,
            },
          },
        },
      };
      if (cachedCurrentDashboardIndex !== -1) {
        cachedDashboards.splice(cachedCurrentDashboardIndex, 1);
        //We need to remove the old dashboardGroup so that we can add the updated one.
      }
      cachedDashboards.push(updateLocalStorageChange);
      localStorage.setItem('activeDashboard', JSON.stringify(cachedDashboards));
    });
  };

  const handleChange = (key) => {
    setFilterColor((prevState) => ({
      ...prevState,
      [key]: true,
    }));
  };

  const removeFromLocalStorage = (dashboardId) => {
    const cachedDashboards = getCachedDashboards();
    const existingActiveDashboard = cachedDashboards?.find(
      (activeDashboard) =>
        activeDashboard.dashboardGroupId === dashboardGroupId,
    );
    if (existingActiveDashboard) {
      const isDashboardIdInExisting = Object.keys(
        existingActiveDashboard.tabs,
      ).includes(dashboardId);
      if (isDashboardIdInExisting) {
        delete existingActiveDashboard.tabs[dashboardId];
      }
    }
    localStorage.setItem('activeDashboard', JSON.stringify(cachedDashboards));
  };

  const resetFilter = () => {
    dispatch({
      type: GENERIC_DASHBOARD_FILTER_DATA_RESET,
      payload: {
        dashboardTab,
      },
    });
    dispatch({ type: DASHBOARD_FILTER_UPDATED, payload: true });
    setFilterColor(filterColorInitialState);
    removeFromLocalStorage(dashboardId);
    setCheckedValues(boolInitialState);
    setFilterApplied(true);
    setOnReset(!onReset);
    onClose();
  };

  const applyingFilters = (dashboardTab) => {
    const applied = onApplyFilter(dashboardTab);
    setFilterColor(filterColorInitialState);
    applied.map((item) => {
      handleChange(item.metric);
    });
    dispatch({ type: DASHBOARD_FILTER_UPDATED, payload: true });
    setLocalStorage(applied);
    setFilterApplied(true);
    onClose();
  };

  const getDateFormat = (filterName) => {
    return filterName !== 'timestampzIsOn' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
  };

  const getDefaultValue = (filterName) => {
    switch (filterName) {
      case 'timestampzIsAfter':
        return moment().startOf('day').format('YYYY-MM-DD HH:mm');
      case 'timestampzIsBefore':
        return moment().endOf('day').format('YYYY-MM-DD HH:mm');
      case 'timestampzIsOn':
        return moment().format('YYYY-MM-DD');
      case 'timestampzCurrent':
        return 'day';
      default:
        return null;
    }
  };

  const getContent = (filter, filterData) => {
    const {
      metric,
      type,
      searchOptions,
      filterMetaData,
      prettyName,
      reportType,
      name,
    } = filter;

    if (type === 'boolean') {
      const options = [
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' },
        { label: 'Null', value: 'null' },
      ];

      const onChangeBoolean = (checked: any) => {
        let selectedFilter;
        let data;
        switch (checked?.length) {
          case 1:
            selectedFilter =
              checked[0] === 'true' || checked[0] === 'false'
                ? 'booleanIsEqualTo'
                : 'booleanIsNull';
            data =
              checked[0] === 'true'
                ? true
                : checked[0] === 'false'
                ? false
                : undefined;
            break;
          case 2:
            selectedFilter =
              checked[0] === 'true'
                ? checked[1] === 'false'
                  ? 'booleanIsNotNull'
                  : 'booleanIsNotFalse'
                : 'booleanIsNotTrue';
            break;
          case 3:
            selectedFilter = 'doNotApplyThisFilter';
        }
        onChangeBoolData(metric)(checked, selectedFilter, data);
      };

      return (
        <div>
          <Checkbox.Group
            className={classes.boolFilterContainer}
            options={options}
            value={checkedValues[metric]}
            onChange={onChangeBoolean}
          ></Checkbox.Group>
        </div>
      );
    }

    const filterListRadio = filtersMetaData[type] || [];
    const filterRadioChangeHandler = onChangeRadio(metric, type);
    const filterProps = {
      type,
      searchOptions,
      metricsName: metric,
      filterMetaData,
      filterListRadio,
      onChangeBoolData: onChangeBoolData(metric),
      onChangeRadio: filterRadioChangeHandler,
      onChangeFilterData: onChangeFilterData(metric),
      filterData,
      showfreezeOption: false,
      selectedFilterReportType: reportType,
      prettyName,
      onApplyFilter,
      dashboardTab,
      checkedValues,
      setFilterApplied,
      onReset,
    };

    const selectedValue = filterData?.[metric];
    const filterMatched = filterListRadio.find(
      (filter) => filter.name === selectedValue?.selectedFilter,
    );

    const FilterComp = searchOptions
      ? selectedValue?.filterInputType === 'inputOrMultiSelect'
        ? FiltersMap.searchableSelect
        : FiltersMap[selectedValue?.filterInputType]
      : FiltersMap[selectedValue?.filterInputType];

    const showTime = {
      defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
    };

    return (
      <div className={classes.filterContainer}>
        <Select
          options={filterListRadio.map((filter) => ({
            label: filter.prettyName,
            value: filter.name,
          }))}
          value={selectedValue?.selectedFilter}
          style={{ width: '100%' }}
          onSelect={(val) => filterProps.onChangeRadio(val)}
        />
        {selectedValue?.type !== 'noInput' && FilterComp && (
          <FilterComp
            multipleSelect={
              searchOptions
                ? selectedValue.filterInputType === 'inputOrMultiSelect'
                : false
            }
            size={'medium'}
            metricsName={metric}
            onChange={filterProps.onChangeFilterData}
            value={selectedValue?.data}
            type={type}
            data={filterMetaData?.data || filterMatched?.data}
            selectedFilterReportType={reportType}
            prettyName={prettyName}
            style={{ width: '100%' }}
            betweenContainer={classes.betweenContainer}
            dateFormat={getDateFormat(selectedValue?.selectedFilter)}
            boolShowTime={selectedValue?.selectedFilter !== 'timestampzIsOn'}
            quickSelection={true}
            showTime={showTime}
            defaultValue={getDefaultValue(selectedValue?.selectedFilter)}
            onReset={onReset}
            isEditing={false}
            isTabletOrMobile={isTabletOrMobile}
          />
        )}
      </div>
    );

    return null;
  };

  useEffect(() => {
    updateSelectedFilter(currentTabFilters[0]);
  }, [dashboardTab]);

  const updateSelectedFilter = (row) => {
    if (!filterData[row.metric]?.selectedFilter) {
      switch (row.type) {
        case 'timestampz':
        case 'timestamp':
        case 'date':
          onChangeRadio(row.metric, row.type)('timestampzBetween');
          break;
        case 'number':
          onChangeRadio(row.metric, row.type)('numberIsGreaterThan');
          break;
        case 'text':
          onChangeRadio(row.metric, row.type)('textIsEqualTo');
          break;
        case 'json':
          onChangeRadio(row.metric, row.type)('textContains');
          break;
        default:
          break;
      }
    }

    const selected = currentTabFilters.find(
      (item) => item.metric === row.metric,
    );
    setCurrentFilter(selected);
  };

  return (
    <>
      <Row
        style={{
          height: '100%',
        }}
      >
        <Col span={10}>
          <Menu
            mode={'vertical'}
            className={classes.menu}
            defaultSelectedKeys={[currentTabFilters[0].metric]}
          >
            {currentTabFilters.map((row, i) => {
              const isSelected = filterColor?.[row.metric];
              return (
                <Menu.Item
                  key={row.metric}
                  onClick={() => {
                    updateSelectedFilter(row);
                  }}
                  className={classes.menuItem}
                  style={{
                    color: isSelected ? '#1890FF' : undefined,
                    backgroundColor: isSelected ? '#e6f7ff' : undefined,
                    fontWeight: isSelected ? 500 : undefined,
                  }}
                >
                  {row.prettyName}
                </Menu.Item>
              );
            })}
          </Menu>
        </Col>
        <Col span={14} className={classes.contentContainer}>
          {getContent(currentFilter, filterData)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row>
            <Divider className={classes.buttonDivider} />
          </Row>
          <Row className={classes.actionsContainer}>
            <Col span={11}>
              <Button style={{ width: '100%' }} onClick={resetFilter}>
                Reset
              </Button>
            </Col>

            <Col span={11}>
              <Button
                type="primary"
                style={{ width: '100%' }}
                onClick={() => applyingFilters(dashboardTab)}
              >
                Apply
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default FilterMenu;
