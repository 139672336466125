import Line from './Line';
import Bar from './Bar';
import Pie from './Pie';
// import DualLineViz from './DualLine';
// import DualBarViz from './DualBar';
// import ExceptionViz from './Exception';
import TableViz from './Table';
// import BigNumberWithTrendLinesViz from './BigNumberWithTrendLines';
import Number from './Number';
import PivotTable from './PivotTable';
import ProgressBar from './ProgressBar';
import htmlView from './htmlView';
import Map from './Map';

const visualisationMap = {
  line: Line,
  bar: Bar,
  pie: Pie,
  // dual_time: DualLineViz,
  // dual_bar: DualBarViz,
  // exception: ExceptionViz,
  table: TableViz,
  // number_with_trend_lines: BigNumberWithTrendLinesViz,
  number: Number,
  pivotTable: PivotTable,
  progressBar: ProgressBar,
  html: htmlView,
  map: Map,
};

export default visualisationMap;
