import React, { EffectCallback, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lodashValues from 'lodash/values';
import lodashKeys from 'lodash/keys';
import lodashUniqBy from 'lodash/uniqBy';
import lodashIsNil from 'lodash/isNil';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import ChartOptionMap from 'src/components/common/ChartOptions';
import {
  CHART_OPTION_VALUE_CHANGED,
  CHART_OPTION_REMOVED,
  CHART_CONFIG_VALUE_CHANGED,
} from 'src/reduxActions/actionNameEnums';
import ChartsList from './ChartsListSelector';
import ChartConfig from './chartConfig/chartConfigs';
import ConditionalFormatting from './ConditionalFormatting/ConditionalFormatting';
import CustomDivider from 'src/components/common/CustomDivider';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { createUseStyles } from 'react-jss';
import { isManualQueryReport } from 'src/components/common/utils/utils';
import { NEW_RELIC_CHART_OPTIONS } from 'src/constants';

const useStyle = createUseStyles({
  basePane: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '4px',
    marginBottom: '20px',
  },
  prettyName: {
    width: '60%',
  },
  inputNumber: {
    margin: '0 16px',
    width: '30%',
  },
  formattingLength: {
    backgroundColor: '#1890FF',
    borderRadius: '1rem',
    color: 'white',
    height: '1.1rem',
    width: '1.1rem',
    marginLeft: '0.5rem',
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.75rem',
    fontWeight: 400,
  },
  formattingBtn: {
    width: '100%',
    marginBottom: '1rem',
    textAlign: 'left',
    backgroundColor: '#F1F2F6',
    border: 'none',
    borderRadius: '4px 4px 0px 0px',
    color: '#303030',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
});

const dependencyType = {
  showWhenValueEquals: 'showWhenValueEquals',
  notShowOptionsSelectedHere: 'notShowOptionsSelectedHere',
};

interface dependencyResult {
  shouldRender;
  selectableList;
}
const allowedName = (name) => {
  const allowedName = ['isStacked', 'isSeriesfield'];
  return allowedName.includes(name);
};
const evaluateDependency = (params) => {
  const { dependency = [], chartSelectedOptionsData, selectableList } = params;
  const result: dependencyResult = {
    shouldRender: true,
    selectableList,
  };
  dependency.forEach((dependency) => {
    const { option, section, value, type } = dependency;
    const dependencyDataValue =
      chartSelectedOptionsData.chartOptions[section]?.[option]?.data || [];
    if (type === dependencyType.showWhenValueEquals) {
      if (value !== dependencyDataValue) {
        result.shouldRender = false;
      }
    } else if (type === dependencyType.notShowOptionsSelectedHere) {
      result.selectableList = selectableList.filter(
        (selecTableOption) =>
          !dependencyDataValue.includes(selecTableOption.value),
      );
    }
  });
  return result;
};

const ChartOptionSection = (props) => {
  const { reportObject } = props;
  const selectedMetricsObject = useSelector((state: any) => state.metrics);
  const summarisationObject = useSelector((state: any) => state.summarization);
  const selectedMetrics = lodashValues(selectedMetricsObject);
  const summarisationMetrics = lodashValues(summarisationObject);
  const combinedLength = lodashUniqBy([...selectedMetrics, ...summarisationMetrics], 'value').length;
  const selectedChart = useSelector(
    (state: any) => state.chartsMetaData.chartType,
  );
  const chartSelectedOptionsData = useSelector(
    (state: any) => state.chartsMetaData,
  );
  const allChartMetaData = useSelector((state: any) => state.reportConfig.charts);
  const operationsMeta = useSelector(
    (state: any) => state.reportConfig.operations,
  );
  const chartSelectedData = allChartMetaData?.[selectedChart] || {};
  const keys = lodashKeys(chartSelectedData);
  const selectedReport = useSelector(
    (state: any) => state.reportMetaData.selectedReport,
  );
  const dbType = useSelector((state: any) => state.reportConfig.reports[selectedReport].dbType);
  const metrics =
    useSelector(
      (state: any) => state.reportConfig.reports?.[selectedReport]?.metrics,
    ) || [];
  const [isConditionalFormattingVisible, setIsConditionalFormattingVisible] =
    useState(false);
  const formatting = useSelector(
    (state: any) => state.chartsMetaData.formatting,
  );
  const classes = useStyle();

  const showConditionalFormatting = () => {
    setIsConditionalFormattingVisible(!isConditionalFormattingVisible);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginBottom: '12px',
        }}
      >
        <div style={{ fontSize: '10px', fontWeight: '400', color: '#8C8C8C' }}>
          Decide how the charts will look and cache
        </div>
      </div>
      <ChartsList />
      {keys.map((key, i) => {
        const sectionMetaData = chartSelectedData[key];
        return (
          <>
            {sectionMetaData.options.length ? (
              <SectionPartContainer
                key={i}
                title={sectionMetaData.prettyName}
                showAddButton={false}
                showTitle={key !== 'rowColumns'}
              >
                <div
                  style={{ width: '100%', height: '100%', paddingTop: '9px' }}
                >
                  {sectionMetaData.options.map((option, ind) => {
                    const {
                      type,
                      prettyName,
                      name,
                      dependency,
                      data,
                      allowedMetricTypes = [],
                      defaultValue,
                      api,
                      apiPayload,
                    } = option;
                    const allMetric =
                      selectedChart === 'pivotTable'
                        ? summarisationMetrics
                        : summarisationMetrics.concat(selectedMetrics);
                    const dependencyResult = evaluateDependency({
                      dependency,
                      chartSelectedOptionsData,
                      selectableList: allMetric,
                    });
                    if (!dependencyResult.shouldRender) {
                      return null;
                    }
                    const selectableArray = lodashUniqBy(
                      dependencyResult.selectableList,
                      metric => metric.value,
                    );
                    let metricData = [];
                    if (isManualQueryReport(reportObject.selectedDataMartType)) {
                      metricData = selectableArray.filter((metric) => {
                        const type = operationsMeta[dbType][metric.operation] || metric.type;
                        return (
                          allowedMetricTypes.includes(type)
                        );
                      });
                    } else {
                      metricData = selectableArray.filter(metric => {
                        const currentMetric = metrics.find(
                          row => row.value === metric.value,
                        );
                        const operations = currentMetric?.operations
                          ? currentMetric.operations
                          : [];
                        const type = metric.operation
                          ? operations.find(
                              element => element.value === metric.operation,
                            ).type
                          : metric.type;
                        return allowedMetricTypes.includes(type) && currentMetric?.groupBy;
                      });
                    }
                    const selectData = selectedChart === 'html' ? { options: NEW_RELIC_CHART_OPTIONS } :
                      allowedMetricTypes.length === 0
                        ? data
                        : { options: metricData };
                    if (allowedName(name) && combinedLength.size <= 2) {
                      return <></>;
                    }
                    return (
                      <>
                        <GeneralOptionsComponent
                          type={type}
                          prettyName={prettyName}
                          name={name}
                          data={selectData}
                          key={ind}
                          section={key}
                          defaultValue={defaultValue}
                          selectedChart={selectedChart}
                          summarisationObject={summarisationObject}
                          apiObject={{
                            api,
                            apiPayload,
                          }}
                        />
                        <CustomDivider style={{ marginBottom: '16px' }} />
                      </>
                    );
                  })}
                </div>
              </SectionPartContainer>
            ) : null}
          </>
        );
      })}
      <ChartConfig />
      {(['table', 'pivotTable', 'number'].includes(selectedChart))
        ? (
          <>
            <Button
              className={classes.formattingBtn}
              onClick={showConditionalFormatting}
            >
              Conditional Formatting
              <span className={classes.formattingLength}>
                {formatting.length}
              </span>
              <div style={{ marginLeft: 'auto' }}>
                <span>
                  <PlusOutlined style={{ color: '#1890FF' }} />
                </span>
              </div>
            </Button>
            <ConditionalFormatting
              visible={isConditionalFormattingVisible}
              setVisible={setIsConditionalFormattingVisible}
            />
          </>
        ): null}
    </>
  );
};

export default memo(ChartOptionSection);

const GeneralOptionsComponent = (props) => {
  const classes = useStyle();
  const {
    type,
    prettyName,
    name,
    data,
    section,
    defaultValue,
    selectedChart,
    summarisationObject,
    apiObject,
  } = props;
  const OptionComponent = ChartOptionMap[type] || ChartOptionMap['toggle'];
  const value = useSelector(
    (state: any) => state.chartsMetaData.chartOptions?.[section]?.[name]?.data,
  );
  const chartOptions = useSelector(
    (state: any) => state.chartsMetaData.chartOptions,
  );
  const rows = chartOptions?.rowColumns?.row?.data || data?.rows || [];
  const columns = chartOptions?.rowColumns?.column?.data || [];
  const draggableColumns = [
    {
      droppableId: 'droppableRow',
      value: rows,
      name: 'rows',
    },
    {
      droppableId: 'droppableColumn',
      value: columns,
      name: 'columns',
    },
  ];
  const isOptionDisabled = () => {
    return (
      name === 'showAggregate' &&
      ['table', 'pivotTable'].includes(selectedChart) &&
      !Object.keys(summarisationObject).length
    );
  };

  const dispatch = useDispatch();
  const onChange = (value) => {
    dispatch({
      type: CHART_OPTION_VALUE_CHANGED,
      payload: {
        optionName: name,
        value,
        section,
      },
    });
  };
  useEffect(() => {
    if (name === 'showAggregate' && value) {
      dispatch({
        type: CHART_CONFIG_VALUE_CHANGED,
        payload: {
          optionName: 'switchDimensions',
          value: false,
          section: 'arrangement',
        },
      });
    }
  }, [value]);
  const onChangeRowColumn = (value) =>
    dispatch({
      type: CHART_OPTION_VALUE_CHANGED,
      payload: {
        optionName: 'row',
        value,
        section: 'rowColumns',
      },
    });
  useEffect(() => {
    if (lodashIsNil(value)) {
      onChange(defaultValue);
    }
  }, [value]);

  const onRemoved = () => {
    dispatch({
      type: CHART_OPTION_REMOVED,
      payload: {
        optionName: name,
      },
    });
  };

  useEffect((): ReturnType<EffectCallback> => onRemoved, []);

  return (
    <OptionComponent
      type={type}
      prettyName={prettyName}
      onChange={onChange}
      onChangeRowColumn={onChangeRowColumn}
      value={value}
      data={data}
      basePane={classes.basePane}
      stylePrettyName={classes.prettyName}
      styleInputNumber={classes.inputNumber}
      disabled={isOptionDisabled()}
      sectionOne={rows}
      sectionTwo={columns}
      draggableColumns={draggableColumns}
      apiObject={apiObject}
    />
  );
};
