/* eslint-disable react/no-danger */
import React, { memo, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import ChartPane from 'src/components/common/Charts';
import TopBar from './visualisationPaneCompponents/TopBar';
import VisualisationPaneImagePlaceHolders from './visualisationPaneCompponents/VisualisationImagePlaceFolder';
import lodash from 'lodash';
import { useChartInteractions } from 'src/components/common/hooks/useChartInterctions';
import ChartInteractionPopOver from 'src/components/common/ChartInteractionPopOver';
import { isManualQueryReport } from 'src/components/common/utils/utils';
import { isHtmlSupported } from 'src/components/utils/utils';

const useStyles = createUseStyles({
  baseCardStyle: {
    borderRadius: '4px 4px 0px 0px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    marginLeft: '16px',
    overflow: 'hidden',
  },
  previewHTML: {
    overflow: 'scroll',
    height: '100%',
  },
  iframe: {
    display: 'block',
  },
});

const VisualisationPane = (props) => {
  const chartRef: any = useRef();
  const updateChartRef = (plot) => {
    if (chartRef) {
      chartRef.current = plot;
    }
  };
  const {
    submitQueryloading,
    chartData,
    fields,
    setFields,
    setSwitcherFields,
    switcherFields,
    stopQuery,
    reportData,
  } = props;
  const chartInteractionHook = useChartInteractions();
  const classes = useStyles();
  const formatting = useSelector(
    (state: any) => state.chartsMetaData.formatting,
  );
  const selectedDataMartType = useSelector(
    (state: any) => state.reportMetaData.dataMartType,
  );
  const showUnderlyingDataDownload = true;
  const selectedReport = useSelector(
    (state: any) => state.reportMetaData.selectedReport,
  );
  const htmlData: string = useSelector(
    (state: any) => state.chartsMetaData.htmlText,
  );
  const url = useSelector((state: any) => state.chartsMetaData.chartData?.url);

  const getSwitcherFields = (data: any = {}) => {
    if (!Object.keys(data).length) return;
    return {
      rows: {
        items: data.fields?.rows.map((row) => {
          return { id: row };
        }),
      },
      columns: {
        items: data.fields?.columns.map((column) => {
          return { id: column };
        }),
      },
      values: {
        selectable: true,
        items: data.fields?.values.map((value) => {
          return { id: value };
        }),
      },
    };
  };

  React.useEffect(() => {
    setFields(chartData.formData?.fields);
    setSwitcherFields(getSwitcherFields(chartData.formData));
  }, [chartData]);

  const onSubmitSwitcher = ({ fields, switcherFields }) => {
    setFields(fields);
    setSwitcherFields(switcherFields);
  };

  return (
    <>
      <div className={classes.baseCardStyle}>
        <TopBar
          tab="default"
          type={chartData.chartType}
          switcherFields={switcherFields}
          onSubmitSwitcher={onSubmitSwitcher}
          reportData={reportData}
          options={{ refreshAutomationSchedules: true }}
        />
        {(lodash.keys(chartData.chartData).length !== 0 ||
          chartData.chartType === 'map' ||
          isHtmlSupported(selectedReport)) && (
          <>
            <ChartInteractionPopOver
              chartInteractionHook={chartInteractionHook}
            />
            <ChartPane
              updateChartRef={updateChartRef}
              type={chartData.chartType}
              loaded={true}
              chartInteractionHook={chartInteractionHook}
              fetching={submitQueryloading && !stopQuery}
              chartData={chartData.chartData}
              formatting={chartData.formatting || formatting}
              visualizeMetaData={chartData.visualizeMetaData}
              formData={chartData.formData}
              showUnderlyingDataDownload={showUnderlyingDataDownload}
              fields={fields}
              setFields={setFields}
              htmlData={htmlData}
              newRelicUrl={url}
            />
          </>
        )}
        {!isHtmlSupported(selectedReport) &&
          lodash.keys(chartData.chartData).length === 0  && chartData.chartType !== 'map' && (
            <VisualisationPaneImagePlaceHolders />
          )}
      </div>
    </>
  );
};
export default memo(VisualisationPane);
