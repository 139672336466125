import React, { memo } from 'react';
import { Input, Button, Row, Col, Select, InputNumber } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { createUseStyles } from 'react-jss';
import { AutomationValueTypes } from 'src/constants';

const useStyles = createUseStyles({
  addButton: {
    color: '#006EC3',
    border: 'none',
  },
  removeButton: {
    border: 'none',
    color: '#FF4D4F',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: '#FF7875',
    },
  },
  inputLabel: {
    color: 'rgba(0, 0, 0, 0.45)',
  },
  entryRow: {
    marginBottom: '1em',
  },
});

interface AdditionalField {
  key: string;
  type: 'string' | 'number' | 'boolean' | 'json';
  value: string | number | boolean;
}

interface AdditionalFieldSectionProps {
  disabled: boolean;
  onChange: (fields: AdditionalField[]) => void;
  value: AdditionalField[];
}

const DEFAULT_FIELD: AdditionalField = { key: '', type: 'string', value: '' };

const AdditionalFieldSection: React.FC<AdditionalFieldSectionProps> = ({
  disabled,
  onChange,
  value: additionalFields = [DEFAULT_FIELD],
}) => {
  const classes = useStyles();
  const map = {
    string: Input,
    number: InputNumber,
    boolean: Select,
    json: Input,
  };

  const onAdditionalFieldskeyChange = (index: number, event) => {
    const newAdditionalFields = [...additionalFields];
    newAdditionalFields[index].key = event.target.value;
    onChange(newAdditionalFields);
  };

  const onAdditionalFieldsTypeChange = (index: number, value) => {
    const newAdditionalFields = [...additionalFields];
    newAdditionalFields[index].type = value;
    if (value === 'boolean') {
      newAdditionalFields[index].value = true;
    }
    if (value === 'number') {
      newAdditionalFields[index].value = 0;
    }
    if (value === 'string' || value === 'json') {
      newAdditionalFields[index].value = '';
    }
    onChange(newAdditionalFields);
  };

  const onAdditionalFieldsValueChange = (index: number, event) => {
    const newAdditionalFields = [...additionalFields];
    if (['number', 'boolean'].includes(additionalFields[index].type)) {
      newAdditionalFields[index].value = event;
    } else {
      newAdditionalFields[index].value = event.target.value;
    }
    onChange(newAdditionalFields);
  };

  const handleAddInput = () => {
    const newAdditionalFields = [...additionalFields, { ...DEFAULT_FIELD }];
    onChange(newAdditionalFields);
  };

  const handleRemoveInput = (index: number) => {
    const newAdditionalFields = [...additionalFields];
    newAdditionalFields.splice(index, 1);
    onChange(newAdditionalFields);
  };
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={7}>
          <span className={classes.inputLabel}>Key</span>
        </Col>
        <Col span={7}>
          <span className={classes.inputLabel}>Type</span>
        </Col>
        <Col span={6}>
          <span className={classes.inputLabel}>Value</span>
        </Col>
        <Col span={4}>
          <Button
            className={classes.addButton}
            type="link"
            shape="circle"
            icon={<PlusCircleOutlined />}
            onClick={handleAddInput}
            disabled={disabled}
          />
        </Col>
      </Row>
      {additionalFields.map((additionalField, index) => {
        if(!additionalField.type) {
          onChange([{...additionalField, type: 'string'}]);
        }
        const CustomInputComponent = map[
          additionalField.type || 'string'
        ] as React.ElementType;
        return (
          <div key={index}>
            <Row gutter={[8, 8]} className={classes.entryRow}>
              <Col span={7}>
                <Input
                  value={additionalField.key}
                  onChange={(event) =>
                    onAdditionalFieldskeyChange(index, event)
                  }
                  disabled={disabled}
                />
              </Col>
              <Col span={7}>
                <Select
                  style={{ width: '100%' }}
                  value={additionalField.type || 'string'}
                  options={AutomationValueTypes}
                  onChange={(value) =>
                    onAdditionalFieldsTypeChange(index, value)
                  }
                  disabled={disabled}
                />
              </Col>
              <Col span={6}>
                <CustomInputComponent
                  style={{ width: '100%' }}
                  value={additionalField.value}
                  onChange={(event) =>
                    onAdditionalFieldsValueChange(index, event)
                  }
                  disabled={disabled}
                  options={[
                    {
                      value: true,
                      label: 'True',
                    },
                    {
                      value: false,
                      label: 'False',
                    },
                  ]}
                />
              </Col>
              <Col span={4}>
                <Button
                  type="link"
                  shape="circle"
                  className={classes.removeButton}
                  icon={<MinusCircleOutlined />}
                  onClick={() => handleRemoveInput(index)}
                  disabled={disabled}
                />
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default memo(AdditionalFieldSection);
