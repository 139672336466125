import React, { memo, useRef } from 'react';
import get from 'lodash/get';
import ChartPane from 'src/components/common/Charts';
import ChartContainerTopBar from './chartsContainerParts/chartsContainerTopbar';
import useChartContainerHook from '../hooks/chartContainerHook';
import { createUseStyles } from 'react-jss';
import ChartInteractionPopOver from 'src/components/common/ChartInteractionPopOver';
import { useChartInteractions } from 'src/components/common/hooks/useChartInterctions';
import { countOfErrors, TszError } from '../util';
import { getChartBorderRadius, getChartPadding, isManualQueryReport } from 'src/components/common/utils/utils';

const useStyles = createUseStyles({
  chartPaneContainer: {
    width: '100%',
    height: '100%',
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
  },
  chartPane: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  chartContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tszError: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const ChartContainer = (props) => {
  const classes = useStyles();
  const chartRef: any = useRef();
  const {
    reportName,
    listenForRefresh,
    dashboardTab,
    pageSize,
    inEditMode,
    onDownloadUnderlyingData,
    dashboardId,
    filters,
    setFilterRemoveFromLocal,
    reportId,
    onDeleteReportFromDashboard,
    onDownload,
    chartReport,
    reportsAll,
    dataMartType,
    clearAllTimeouts,
    isStacked,
  } = props;
  const chartContainerHook = useChartContainerHook(
    reportId,
    filters,
    listenForRefresh,
    dashboardTab,
  );
  const {
    loaded,
    error,
    chartType,
    chartData,
    chartOptions,
    formData,
    fetchReport,
    dateRange,
    visualizeMetaData,
    chartConfig,
    formatting,
    columnWidth,
    reportDescription,
    htmlData,
    reportType,
    newRelicUrl,
    lastRefreshTime,
  } = chartContainerHook;

  const borderRadius = getChartBorderRadius(chartConfig?.chartBorderRadius);
  const padding = getChartPadding(chartConfig?.chartPadding);

  const getDefaultSwitcherFields = (data: any = {}) => {
    if (!Object.keys(data).length) return;
    return {
      rows: {
        items: data.fields?.rows.map((row) => {
          return { id: row };
        }),
      },
      columns: {
        items: data.fields?.columns.map((column) => {
          return { id: column };
        }),
      },
      values: {
        selectable: true,
        items: data.fields?.values.map((value) => {
          return { id: value };
        }),
      },
    };
  };

  const [switcherFields, setSwitcherFields] = React.useState(
    getDefaultSwitcherFields(formData),
  );
  const [fields, setFields] = React.useState(formData?.fields);

  React.useEffect(() => {
    setFields(formData?.fields);
    setSwitcherFields(getDefaultSwitcherFields(formData));
  }, [formData]);
  const isReportNameVisibleOnDashboard = get(chartConfig, [
    'graphic',
    'isReportNameVisibleOnDashboard',
    'data',
  ], true);

  const onSubmitSwitcher = ({ fields, switcherFields }) => {
    setFields(fields);
    setSwitcherFields(switcherFields);
  };

  const onDelete = () => onDeleteReportFromDashboard(reportId, dashboardId);
  const onClickDownloadChartData = () => onDownload(reportId, filters, []);
  const onClickDownloadUnderlyingChartData = () =>
    onDownloadUnderlyingData(reportId, filters, []);
  const updateChartRef = (plot) => {
    if (chartRef) {
      chartRef.current = plot;
    }
  };
  const chartInteractionHook = useChartInteractions();
  const containerRef = useRef(null);

  const visualizeMetaDataObj = JSON.parse(JSON.stringify(visualizeMetaData));
  const filterCount = filters ? filters.length : 0;
  const dbType = reportsAll?.[chartReport.reportType]?.dbType;
  const filterNameAccessKey = 'name';
  const countErrors = countOfErrors(filters, visualizeMetaDataObj.summarizeData, true, filterNameAccessKey, dbType);
  const showUnderlyingDataDownload = true;
  const renderChartPane = () => {
    if (countErrors !== 0) {
      if (filterCount > 1 && filterCount === countErrors) {
        return <p className={classes.tszError}>{TszError('multiple')}</p>;
      }
      if (filterCount === 1) {
        return <p className={classes.tszError}>{TszError('single')}</p>;
      }
    }
    return (
      <ChartPane
        updateChartRef={updateChartRef}
        filters={filters}
        fetching={fetchReport}
        loaded={loaded}
        error={error}
        type={chartType}
        chartData={chartData}
        pageSize={pageSize}
        visualizeMetaData={visualizeMetaData}
        chartInteractionHook={chartInteractionHook}
        chartOptions={chartOptions}
        formData={formData}
        reportId={reportId}
        chartConfig={chartConfig}
        formatting={formatting}
        columnWidth={columnWidth}
        showUnderlyingDataDownload={showUnderlyingDataDownload}
        chartsFromDashboard={true}
        fields={fields}
        setFields={setFields}
        dataMartType={dataMartType}
        htmlData={htmlData}
        reportType={reportType}
        newRelicUrl={newRelicUrl}
        isStacked={isStacked}
      />
    );
  };

  return (
    <div className={classes.chartPaneContainer} ref={containerRef} style={{ borderRadius, padding }}>
      <div className={classes.chartContainer}>
        {(isReportNameVisibleOnDashboard || inEditMode) && (
          <ChartContainerTopBar
            onClickDownloadChartData={onClickDownloadChartData}
            onClickDownloadUnderlyingChartData={
              onClickDownloadUnderlyingChartData
            }
            chartType={chartType}
            chartData={chartData}
            chartRef={chartRef}
            onDelete={onDelete}
            reportName={reportName}
            reportDescription={reportDescription}
            reportId={reportId}
            setFilterRemoveFromLocal={setFilterRemoveFromLocal}
            inEditMode={inEditMode}
            reportDateRange={dateRange}
            formData={formData}
            switcherFields={switcherFields}
            onSubmitSwitcher={onSubmitSwitcher}
            chartConfig={chartConfig}
            reportType={reportType}
            dataMartType={dataMartType}
            clearAllTimeouts={clearAllTimeouts}
            fetching={fetchReport}
            lastRefreshTime={lastRefreshTime}
          />
        )}
        <div className={classes.chartPane}>
          <ChartInteractionPopOver
            isChartFromDashboard={true}
            containerRef={containerRef}
            chartInteractionHook={chartInteractionHook}
          />
          {renderChartPane()}
        </div>
      </div>
    </div>
  );
};

export default memo(ChartContainer);
