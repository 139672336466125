import React from 'react';
import { colors, tableColor } from 'src/components/utils/colors';
import { DEFAULT_THEME } from 'src/constants';
const Configs = (xAxisTitle, yAxisTitle, barType: any = '') => {
  const commonConfigs = {
    prettyName: 'Common',
    name: 'common',
    type: 'collapse',
    config: [
      {
        type: 'dateRangeVisible',
        child: null,
        defaultValue: true,
      },
      {
        type: 'chartPaddingCollapse',
        defaultValue: false,
        child: [
          {
            type: 'chartPaddingLeft',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'chartPaddingRight',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'chartPaddingTop',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'chartPaddingBottom',
            child: null,
            defaultValue: 0,
          },
        ],
      },
      {
        type: 'chartBorderRadiusCollapse',
        defaultValue: false,
        child: [
          {
            type: 'chartBorderRadiusTopLeft',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'chartBorderRadiusTopRight',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'chartBorderRadiusBottomLeft',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'chartBorderRadiusBottomRight',
            child: null,
            defaultValue: 0,
          },
        ],
      },
    ],
  };
  const configs = {
    line: {
      graphic: {
        prettyName: 'Graphic',
        name: 'graphic',
        type: 'collapse',
        config: [
          {
            type: 'themeCategory',
            child: null,
            defaultValue: DEFAULT_THEME,
          },
          {
            type: 'category',
            child: null,
            defaultValue: 'Default',
          },
          {
            type: 'color',
            child: null,
            defaultValue: colors[DEFAULT_THEME],
          },
          {
            type: 'columnSizeLine', //thickness
            child: null,
            defaultValue: 2,
          },
          {
            type: 'smooth',
            child: null,
            defaultValue: true,
          },
          {
            type: 'toolTip',
            child: null,
            defaultValue: true,
          },
          {
            type: 'commaSeparator',
            child: null,
            defaultValue: 'none',
          },
        ],
      },
      graphic_label: {
        prettyName: 'Graphic Label',
        name: 'graphicLabel',
        type: 'switchCollapse',
        defaultValue: false,
        config: [
          {
            type: 'offsetX',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'offsetY',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'labelSize',
            child: null,
            defaultValue: 12,
          },
        ],
      },
      legend: {
        prettyName: 'Legend',
        name: 'legend',
        type: 'switchCollapse',
        defaultValue: true,
        config: [
          {
            type: 'locationPie',
            child: null,
            defaultValue: 'top-left',
          },
          {
            type: 'flipPage',
            child: null,
            defaultValue: true,
          },
          {
            type: 'offsetX',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'offsetY',
            child: null,
            defaultValue: 0,
          },
        ],
      },
      point: {
        prettyName: 'Point',
        name: 'point',
        type: 'switchCollapse',
        defaultValue: true,
        config: [
          {
            type: 'size',
            child: null,
            defaultValue: 3,
          },
          {
            type: 'shape',
            child: null,
            defaultValue: 'circle',
          },
        ],
      },
      X_axis: {
        prettyName: 'X axis',
        name: 'xAxis',
        type: 'switchCollapse',
        defaultValue: true,
        config: [
          {
            type: 'xTitle',
            defaultValue: false,
            child: [
              {
                type: 'text',
                child: null,
                defaultValue: xAxisTitle ? xAxisTitle : 'x',
              },
            ],
          },
          {
            type: 'axisRulers',
            child: null,
            defaultValue: false,
          },
          {
            type: 'xAxisLabel',
            defaultValue: true,
            child: [
              {
                type: 'offsetX',
                child: null,
                defaultValue: 0,
              },
              {
                type: 'offsetY',
                child: null,
                defaultValue: 0,
              },
            ],
          },
          {
            type: 'tickLine',
            child: null,
            defaultValue: false,
          },
          {
            type: 'grid',
            child: null,
            defaultValue: false,
          },
          {
            type: 'autoRotateLabel',
            child: null,
            defaultValue: false,
          },
          {
            type: 'autoHideLabel',
            child: null,
            defaultValue: true,
          },
        ],
      },
      Y_axis: {
        prettyName: 'Y axis',
        name: 'yAxis',
        type: 'switchCollapse',
        defaultValue: true,
        config: [
          {
            type: 'yTitle',
            defaultValue: false,
            child: [
              {
                type: 'text',
                child: null,
                defaultValue: yAxisTitle ? yAxisTitle : 'y',
              },
            ],
          },
          {
            type: 'axisRulers',
            child: null,
            defaultValue: false,
          },
          {
            type: 'yAxisLabel',
            defaultValue: true,
            child: [
              {
                type: 'offsetX',
                child: null,
                defaultValue: 0,
              },
              {
                type: 'offsetY',
                child: null,
                defaultValue: 0,
              },
            ],
          },
          {
            type: 'tickLine',
            child: null,
            defaultValue: false,
          },
          {
            type: 'grid',
            child: null,
            defaultValue: true,
          },
          {
            type: 'autoRotateLabel',
            child: null,
            defaultValue: false,
          },
          {
            type: 'autoHideLabel',
            child: null,
            defaultValue: false,
          },
        ],
      },
    },
    bar: {
      graphic: {
        prettyName: 'Graphic',
        name: 'graphic',
        type: 'collapse',
        config: [
          {
            type: 'themeCategory',
            child: null,
            defaultValue: DEFAULT_THEME,
          },
          {
            type: 'category',
            child: null,
            defaultValue: 'Default',
          },
          {
            type: 'color',
            child: null,
            defaultValue: colors[DEFAULT_THEME],
          },
          {
            type: 'columnSizeBar',
            child: null,
            defaultValue: 'default',
          },
          {
            type: 'toolTip',
            child: null,
            defaultValue: true,
          },
          {
            type: 'percentageThreshold',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'commaSeparator',
            child: null,
            defaultValue: 'none',
          },
        ],
      },
      graphic_label: {
        prettyName: 'Graphic Label',
        name: 'graphicLabel',
        type: 'switchCollapse',
        defaultValue: false,
        config: [
          {
            type: barType === 'horizontal' ? 'barPosition' : 'columnPosition',
            child: null,
            defaultValue: barType === 'horizontal' ? 'right' : 'top',
          },
          {
            type: 'offsetX',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'offsetY',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'labelSize',
            child: null,
            defaultValue: 12,
          },
        ],
      },
      legend: {
        prettyName: 'Legend',
        name: 'legend',
        type: 'switchCollapse',
        defaultValue: true,
        config: [
          {
            type: 'locationPie',
            child: null,
            defaultValue: 'top-left',
          },
          {
            type: 'flipPage',
            child: null,
            defaultValue: true,
          },
          {
            type: 'offsetX',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'offsetY',
            child: null,
            defaultValue: 0,
          },
        ],
      },
      X_axis: {
        prettyName: 'X axis',
        name: 'xAxis',
        type: 'switchCollapse',
        defaultValue: true,
        config: [
          {
            type: 'xTitle',
            defaultValue: false,
            child: [
              {
                type: 'text',
                child: null,
                defaultValue: xAxisTitle ? xAxisTitle : 'x',
              },
            ],
          },
          {
            type: 'axisRulers',
            child: null,
            defaultValue: false,
          },
          {
            type: 'xAxisLabel',
            defaultValue: true,
            child: [
              {
                type: 'offsetX',
                child: null,
                defaultValue: 0,
              },
              {
                type: 'offsetY',
                child: null,
                defaultValue: 0,
              },
            ],
          },
          {
            type: 'tickLine',
            child: null,
            defaultValue: false,
          },
          {
            type: 'grid',
            child: null,
            defaultValue: false,
          },
          {
            type: 'autoRotateLabel',
            child: null,
            defaultValue: false,
          },
          {
            type: 'autoHideLabel',
            child: null,
            defaultValue: false,
          },
        ],
      },
      Y_axis: {
        prettyName: 'Y axis',
        name: 'yAxis',
        type: 'switchCollapse',
        defaultValue: true,
        config: [
          {
            type: 'yTitle',
            defaultValue: false,
            child: [
              {
                type: 'text',
                child: null,
                defaultValue: yAxisTitle ? yAxisTitle : 'y',
              },
            ],
          },
          {
            type: 'axisRulers',
            child: null,
            defaultValue: false,
          },
          {
            type: 'yAxisLabel',
            defaultValue: true,
            child: [
              {
                type: 'offsetX',
                child: null,
                defaultValue: 0,
              },
              {
                type: 'offsetY',
                child: null,
                defaultValue: 0,
              },
            ],
          },
          {
            type: 'tickLine',
            child: null,
            defaultValue: false,
          },
          {
            type: 'grid',
            child: null,
            defaultValue: true,
          },
          {
            type: 'autoRotateLabel',
            child: null,
            defaultValue: false,
          },
          {
            type: 'autoHideLabel',
            child: null,
            defaultValue: false,
          },
        ],
      },
    },
    pie: {
      graphic: {
        prettyName: 'Graphic',
        name: 'graphic',
        type: 'collapse',
        config: [
          {
            type: 'themeCategory',
            child: null,
            defaultValue: DEFAULT_THEME,
          },
          {
            type: 'category',
            child: null,
            defaultValue: 'Default',
          },
          {
            type: 'color',
            child: null,
            defaultValue: colors[DEFAULT_THEME],
          },
          {
            type: 'radius',
            child: null,
            defaultValue: 90,
          },
          {
            type: 'toolTip',
            child: null,
            defaultValue: true,
          },
          {
            type: 'percentageThreshold',
            child: null,
            defaultValue: 2,
          },
          {
            type: 'commaSeparator',
            child: null,
            defaultValue: 'none',
          },
        ],
      },
      legend: {
        prettyName: 'Legend',
        name: 'legend',
        type: 'switchCollapse',
        defaultValue: true,
        config: [
          {
            type: 'locationPie',
            child: null,
            defaultValue: 'right',
          },
          {
            type: 'flipPage',
            child: null,
            defaultValue: true,
          },
          {
            type: 'offsetX',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'offsetY',
            child: null,
            defaultValue: 0,
          },
        ],
      },
      graphic_label: {
        prettyName: 'Graphic Label',
        name: 'graphicLabel',
        type: 'switchCollapse',
        defaultValue: false,
        config: [
          {
            type: 'type',
            child: null,
            defaultValue: 'inner',
          },
          {
            type: 'offset',
            child: null,
            defaultValue: 1,
          },
          {
            type: 'offsetX',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'offsetY',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'labelSize',
            child: null,
            defaultValue: 14,
          },
        ],
      },
    },
    pivotTable: {
      graphic: {
        prettyName: 'Graphic',
        name: 'graphic',
        type: 'collapse',
        config: [
          {
            type: 'tableCategory',
            child: null,
            defaultValue: 'Default',
          },
          {
            type: 'color',
            child: null,
            defaultValue: tableColor,
          },
          {
            type: 'toolTip',
            child: null,
            defaultValue: false,
          },
          {
            type: 'textAlign',
            child: null,
            defaultValue: 'left',
          },
          {
            type: 'numberAlign',
            child: null,
            defaultValue: 'right',
          },
          {
            type: 'commaSeparator',
            child: null,
            defaultValue: 'none',
          },
        ],
      },
      arrangement: {
        prettyName: 'Arrangement',
        name: 'arrangement',
        type: 'collapse',
        config: [
          {
            type: 'switchDimensions',
            child: null,
            defaultValue: false,
          },
        ],
      },
    },
    table: {
      graphic: {
        prettyName: 'Graphic',
        name: 'graphic',
        type: 'collapse',
        config: [
          {
            type: 'tableCategory',
            child: null,
            defaultValue: 'Default',
          },
          {
            type: 'color',
            child: null,
            defaultValue: tableColor,
          },
          {
            type: 'toolTip',
            child: null,
            defaultValue: false,
          },
          {
            type: 'textAlign',
            child: null,
            defaultValue: 'left',
          },
          {
            type: 'numberAlign',
            child: null,
            defaultValue: 'right',
          },
          {
            type: 'commaSeparator',
            child: null,
            defaultValue: 'none',
          },
        ],
      },
    },
    number: {
      graphic: {
        prettyName: 'Graphic',
        name: 'graphic',
        type: 'collapse',
        config: [
          {
            type: 'label',
            child: null,
            defaultValue: true,
          },
          {
            type: 'toolTip',
            child: null,
            defaultValue: true,
          },
          {
            type: 'commaSeparator',
            child: null,
            defaultValue: 'international',
          },
          {
            type: 'verticalRepresentation',
            child: null,
            defaultValue: false,
          },
        ],
      },
    },
    progressBar: {
      graphic: {
        prettyName: 'Graphic',
        name: 'graphic',
        type: 'collapse',
        config: [
          {
            type: 'themeCategory',
            child: null,
            defaultValue: DEFAULT_THEME,
          },
          {
            type: 'category',
            child: null,
            defaultValue: 'Default',
          },
          {
            type: 'color',
            child: null,
            defaultValue: colors[DEFAULT_THEME],
          },
          {
            type: 'columnSizeBar',
            child: null,
            defaultValue: 'default',
          },
          {
            type: 'toolTip',
            child: null,
            defaultValue: true,
          },
        ],
      },
      graphic_label: {
        prettyName: 'Graphic Label',
        name: 'graphicLabel',
        type: 'switchCollapse',
        defaultValue: false,
        config: [
          {
            type: 'barPosition',
            child: null,
            defaultValue: 'middle',
          },
          {
            type: 'offsetX',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'offsetY',
            child: null,
            defaultValue: 0,
          },
          {
            type: 'labelSize',
            child: null,
            defaultValue: 10,
          },
        ],
      },
      legend: {
        prettyName: 'Legend',
        name: 'legend',
        type: 'switchCollapse',
        defaultValue: true,
        config: [
          {
            type: 'progressBarPosition',
            child: null,
            defaultValue: 'top-left',
          },
        ],
      },
      X_axis: {
        prettyName: 'X axis',
        name: 'xAxis',
        type: 'switchCollapse',
        defaultValue: true,
        config: [
          {
            type: 'xTitle',
            defaultValue: true,
            child: [
              {
                type: 'text',
                child: null,
                defaultValue: xAxisTitle ? xAxisTitle : 'X-Axis',
              },
            ],
          },
        ],
      },
      Y_axis: {
        prettyName: 'Y axis',
        name: 'yAxis',
        type: 'switchCollapse',
        defaultValue: true,
        config: [
          {
            type: 'yTitle',
            defaultValue: false,
            child: [
              {
                type: 'text',
                child: null,
                defaultValue: yAxisTitle ? yAxisTitle : 'y',
              },
            ],
          },
        ],
      },
    },
    html: {
      graphic: {
        prettyName: 'Graphic',
        name: 'graphic',
        type: 'collapse',
        config: [
          {
            type: 'isReportNameVisibleOnDashboard',
            child: null,
            defaultValue: true,
          },
          {
            type: 'timePicker',
            child: null,
            defaultValue: true,
          },
          {
            type: 'scrollable',
            child: null,
            defaultValue: true,
          },
        ],
      },
    },
    map: {},
  };
  for (const key in configs) {
    if (configs?.[key]) {
      const config = configs[key];
      config.common = commonConfigs;
    }
  }
  return configs;
};
export default Configs;
