import { useState } from 'react';
import { Button, Modal, message, Input, Space, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import {
  SAVE_DASHBOARD_FILTER_VIEW,
  DELETE_DASHBOARD_FILTER_VIEW,
} from 'src/api/routes';
import { SET_DASHBOARD_FILTER_VIEWS } from 'src/reduxActions/actionNameEnums';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';

const { Text } = Typography;

const useStyle = createUseStyles({
  filterViewActionBody: {
    display: 'flex',
    alignItems: 'center',
  },
  viewName: {
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: '15px',
    marginRight: '10%',
  },
  exclamationIcon: {
    color: '#faad14',
    fontSize: 24,
  },
});

const FilterViewModal = (props) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [filterViewName, setFilterViewName] = useState('');
  const {
    filterViewModalVisibility,
    setFilterViewModalVisibility,
    setFilterViewsPresent,
    dashboardGroupId,
    dashboardId,
    filters,
    dashboardFilterViews,
    deleteFilterViewName,
  } = props;

  const [saveFilterView, { loading: savingFilterView }] = useLazyAPI(
    SAVE_DASHBOARD_FILTER_VIEW,
    {
      type: 'POST',
      onCompleted: (data) => {
        setFilterViewModalVisibility(false);
        const updatedFilterViews = dashboardFilterViews.map((item) => {
          if (item.dashboardId === dashboardId) {
            const updatedFilterViews = [
              ...(item.filterViews || []),
              {
                name: filterViewName,
                filters: data,
              },
            ];
            return {
              ...item,
              filterViews: updatedFilterViews,
            };
          }
          return item;
        });

        dispatch({
          type: SET_DASHBOARD_FILTER_VIEWS,
          payload: updatedFilterViews,
        });
        setFilterViewsPresent(true);
        message.success('Filter View Saved Successfully');
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [deleteFilterView, { loading: deletingFilterView }] = useLazyAPI(
    DELETE_DASHBOARD_FILTER_VIEW,
    {
      type: 'POST',
      onCompleted: (data) => {
        setFilterViewModalVisibility(false);
        const updatedFilterViews = dashboardFilterViews.map((item) => {
          if (item.dashboardId === dashboardId) {
            return {
              dashboardId,
              filterViews: item.filterViews.filter(
                (filterView) => filterView.name !== data,
              ),
            };
          }
          return item;
        });

        dispatch({
          type: SET_DASHBOARD_FILTER_VIEWS,
          payload: updatedFilterViews,
        });

        if (
          !updatedFilterViews.find(
            (filterView) => filterView.dashboardId === dashboardId,
          )?.length
        ) {
          setFilterViewsPresent(false);
        }

        message.success('Filter View Deleted Successfully');
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const onCancel = () => {
    setFilterViewModalVisibility(false);
  };

  const onNextFilterView = (isDeleteModal) => {
    !isDeleteModal
      ? saveFilterView({
          variables: {
            dashboardGroupId,
            dashboardId,
            name: filterViewName,
            filters,
          },
        })
      : deleteFilterView({
          variables: {
            dashboardGroupId,
            dashboardId,
            name: deleteFilterViewName,
          },
        });
  };

  return (
    <Modal
      title={
        deleteFilterViewName ? (
          <div>Delete View</div>
        ) : (
          <div>Save as a View</div>
        )
      }
      centered
      width={'30%'}
      bodyStyle={{ height: '6.5rem' }}
      visible={filterViewModalVisibility}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>Cancel</Button>,
        <Button
          type="primary"
          onClick={() => onNextFilterView(deleteFilterViewName)}
          disabled={deleteFilterViewName ? false : !filterViewName}
          loading={savingFilterView || deletingFilterView}
          style={{
            backgroundColor: deleteFilterViewName ? '#d32f2f' : '',
            borderColor: deleteFilterViewName ? '#d32f2f' : '',
            color: deleteFilterViewName ? '#fff' : '',
          }}
        >
          {deleteFilterViewName ? 'Yes, Delete' : 'Save'}
        </Button>,
      ]}
    >
      <div className={classes.filterViewActionBody}>
        {deleteFilterViewName ? (
          <Space align="start">
            <ExclamationCircleFilled className={classes.exclamationIcon} />
            <div>
              <Text strong>Are you sure you want to delete this view?</Text>
              <br />
              <Text type="secondary">This action cannot be reversed.</Text>
            </div>
          </Space>
        ) : (
          <>
            <div className={classes.viewName}>{'Name: '} </div>
            <Input
              placeholder="Enter Filter View Name"
              value={filterViewName}
              onChange={(e) => setFilterViewName(e.target.value)}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default FilterViewModal;
