import { Divider, message, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import {
  SET_CHART_TYPE,
  REMOVE_DRILLDOWN,
  CHART_OPTION_VALUE_CHANGED,
} from 'src/reduxActions/actionNameEnums';
import lodashValues from 'lodash/values';
import lodashKeys from 'lodash/keys';
import lodashUniq from 'lodash/uniq';
import SectionPartContainer from 'src/components/common/sectionPartContainer';
import { ReactComponent as LineSVG } from 'src/assets/chartIcons/Line.svg';
import { ReactComponent as BarSVG } from 'src/assets/chartIcons/Bar.svg';
import { ReactComponent as TableSVG } from 'src/assets/chartIcons/Table.svg';
import { ReactComponent as PivotSVG } from 'src/assets/chartIcons/Pivot.svg';
import { ReactComponent as ProgressBarSVG } from 'src/assets/chartIcons/ProgressBar.svg';
import { ReactComponent as PieSVG } from 'src/assets/chartIcons/Pie.svg';
import { ReactComponent as NumberSVG } from 'src/assets/chartIcons/Number.svg';
import { Html5Outlined, AimOutlined } from '@ant-design/icons';
import { isHtmlSupported } from 'src/components/utils/utils';

const useStyles = createUseStyles({
  baseContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '8px',
    marginBottom: '8px',
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
  },
  childChartSelector: {
    display: 'flex',
    height: '32px',
    width: '32px',
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    marginRight: '10px',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedChart: {
    borderColor: '#1890FF',
  },
  text: {
    color: '#303030',
    fontSize: '12px',
    height: '14px',
    lineHeight: '14px',
    marginBottom: '0.5rem',
  },
});

const options = [
  {
    icon: <TableSVG fill="#1890FF" />,
    iconDisabled: <TableSVG fill="#8C8C8C" />,
    value: 'table',
    toolTip: 'Table',
    needAggregate: false,
  },
  {
    icon: <BarSVG fill="#1890FF" />,
    iconDisabled: <BarSVG fill="#8C8C8C" />,
    value: 'bar',
    toolTip: 'Bar',
    needAggregate: true,
  },
  {
    icon: <PieSVG fill="#1890FF" />,
    iconDisabled: <PieSVG fill="#8C8C8C" />,
    value: 'pie',
    toolTip: 'Pie',
    needAggregate: true,
  },
  {
    icon: <LineSVG fill="#1890FF" />,
    iconDisabled: <LineSVG fill="#8C8C8C" />,
    value: 'line',
    toolTip: 'Line',
    needAggregate: true,
  },
  {
    icon: <NumberSVG fill="#1890FF" />,
    iconDisabled: <NumberSVG fill="#8C8C8C" />,
    value: 'number',
    toolTip: 'Number',
    needAggregate: true,
  },
  {
    icon: <PivotSVG fill="#1890FF" />,
    iconDisabled: <PivotSVG fill="#8C8C8C" />,
    value: 'pivotTable',
    toolTip: 'Pivot Table',
  },
  {
    icon: <ProgressBarSVG fill="#1890FF" />,
    iconDisabled: <ProgressBarSVG fill="#8C8C8C" />,
    value: 'progressBar',
    toolTip: 'Progress Bar',
    needAggregate: true,
  },
  {
    icon: <Html5Outlined style={{ color: '#1890FF' }} />,
    iconDisabled: <Html5Outlined />,
    value: 'html',
    toolTip: 'HTML view',
  },
  {
    icon: <AimOutlined style={{ color: '#1890FF' }} />,
    iconDisabled: <AimOutlined />,
    value: 'map',
    toolTip: 'Map view',
    needAggregate: false,
  },
];

const getIsEnabled = (params) => {
  const {
    needAggreGate,
    aggregateSelected,
    metricsSelected,
    isAggreGated,
    chartType,
    isColumnAggregated,
    selectedReport,
  } = params;
  // html chartType will only be enabled if the report is html supported, and rest of the chartTypes will be disabled
  if (isHtmlSupported(selectedReport) && chartType !== 'html') {
    return false;
  }
  // html chartType will be disabled if the report is not html supported
  if (chartType === 'html' && !isHtmlSupported(selectedReport)) {
    return false;
  }
  if (chartType === 'pivotTable' || chartType === 'progressBar') {
    const summarisationKeys = lodashKeys(aggregateSelected);
    const metricsKeys = lodashKeys(metricsSelected);
    const combinedUniqueColumns = lodashUniq(
      summarisationKeys.concat(metricsKeys),
    );
    const totalColumns = summarisationKeys.length + metricsKeys.length;
    return chartType === 'pivotTable'
      ? summarisationKeys.length >= 1 && combinedUniqueColumns.length >= 2
      : summarisationKeys.length >= 1 &&
          ((metricsKeys.length === 2 &&
            combinedUniqueColumns.length < totalColumns) ||
            metricsKeys.length < 2);
  }

  if (chartType === 'number') {
    return isAggreGated || isColumnAggregated;
  }
  return !needAggreGate || isAggreGated;
};

const ChartOption = (props) => {
  const dispatch = useDispatch();
  const {
    option,
    isAggreGated,
    aggregateSelected,
    metricsSelected,
    selectedChart,
    isColumnAggregated,
    classes,
  } = props;
  const summarisationObject = useSelector((state: any) => state.summarization);
  const selectedReport = useSelector(
    (state: any) => state.reportMetaData.selectedReport,
  );
  const summarisationMetrics = lodashValues(summarisationObject);
  const isEnabled = getIsEnabled({
    needAggreGate: option.needAggregate,
    isAggreGated,
    aggregateSelected,
    metricsSelected,
    chartType: option.value,
    isColumnAggregated,
    selectedReport,
  });
  const onChartClicked = (value, isEnabled) => () => {
    if (isEnabled) {
      dispatch({
        type: SET_CHART_TYPE,
        payload: {
          chartType: value,
        },
      });
      dispatch({
        type: REMOVE_DRILLDOWN,
      });
      if (value === 'pivotTable') {
        const data = summarisationMetrics.map((item) => ({
          ...item,
          chartSortBy: 'ASC',
        }));
        dispatch({
          type: CHART_OPTION_VALUE_CHANGED,
          payload: {
            section: 'rowColumns',
            optionName: 'row',
            value: data,
          },
        });
      }
    } else {
      message.warning('Cannot select a disabled chart');
    }
  };

  return (
    <Tooltip title={option.toolTip}>
      <div
        key={option.value}
        onClick={onChartClicked(option.value, isEnabled)}
        className={`${classes.childChartSelector}${
          selectedChart === option.value ? ' ' + classes.selectedChart : ''
        }`}
      >
        {isEnabled ? option.icon : option.iconDisabled}
      </div>
    </Tooltip>
  );
};

const ChartsList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedChart = useSelector(
    (state: any) => state.chartsMetaData.chartType,
  );
  const selectedDataMartType = useSelector(
    (state: any) => state.reportMetaData.dataMartType,
  );
  const columnAggregateSelected = useSelector(
    (state: any) => state.reportMetaData.isAggregated,
  );
  const aggregateSelected = useSelector((state: any) => state.summarization);
  const metricsSelected = useSelector((state: any) => state.metrics);
  const [isAggreGated, setIsAggregated] = useState(false);
  useEffect(() => {
    const aggregateList = lodashValues(aggregateSelected);
    const metricsKeys = lodashKeys(metricsSelected);
    const summarisationKeys = lodashKeys(aggregateSelected);
    const combinedUniqueColumns = lodashUniq(
      summarisationKeys.concat(metricsKeys),
    );
    const totalColumns = summarisationKeys.length + metricsKeys.length;
    if (aggregateList.length === 0) {
      if (
        selectedChart !== 'html' &&
        selectedChart !== 'map' &&
        (selectedChart !== 'number' || !columnAggregateSelected)
      ) {
        dispatch({
          type: SET_CHART_TYPE,
          payload: {
            chartType: 'table',
          },
        });
      }
      setIsAggregated(false);
    } else {
      if (selectedChart === 'progressBar' && metricsKeys.length >= 2) {
        if (
          metricsKeys.length === 2 &&
          combinedUniqueColumns.length === totalColumns
        ) {
          dispatch({
            type: SET_CHART_TYPE,
            payload: {
              chartType: 'table',
            },
          });
        } else if (metricsKeys.length > 2) {
          dispatch({
            type: SET_CHART_TYPE,
            payload: {
              chartType: 'table',
            },
          });
        }
      }
      setIsAggregated(true);
    }
  }, [aggregateSelected, columnAggregateSelected, metricsSelected]);

  return (
    <>
      <SectionPartContainer title="Chart Type" showAddButton={false}>
        <div className={classes.baseContainer}>
          <span className={classes.text}>Choose Chart Type</span>
          <div className={classes.selectContainer}>
            {options.map((option, index) => (
              <ChartOption
                option={option}
                key={index}
                isAggreGated={isAggreGated}
                aggregateSelected={aggregateSelected}
                metricsSelected={metricsSelected}
                selectedChart={selectedChart}
                isColumnAggregated={columnAggregateSelected}
                classes={classes}
              />
            ))}
          </div>
        </div>
      </SectionPartContainer>
    </>
  );
};

export default ChartsList;
