import React, { memo, useEffect, useState } from 'react';
import { Affix, Popover, Tabs, Breadcrumb } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import lodashKeys from 'lodash/keys';
import { useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import NavigationPane from '../pages/dashboard/dashboardParts/NavigationPane';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import lodashCapitalize from 'lodash/capitalize';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import DropDownNaigation from './dropDownNavigation';
import {
  CHANGE_DOWNLOAD_MODAL_STATE,
  CHANGE_NAV_TAB,
} from 'src/reduxActions/actionNameEnums';
import { getProjectName } from 'src/components/common/utils/utils';
import { projectNames } from 'src/constants';
import { getNavBarTheme } from 'src/components/common/utils/utils';

const useStyle = createUseStyles({
  basePane: (navBarTheme) => ({
    width: '100%',
    height: '56px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: navBarTheme['navbar-background'] ?? '#FFFFFF',
    boxShadow: 'inset 0px -1px 0px #F0F0F0',
    paddingRight: '16px',
    '& .ant-breadcrumb-separator': {
      color: navBarTheme['text-color'] ?? undefined,
    },
  }),
  breadCrumbsPane: {
    display: 'flex',
    alignItems: 'center',
  },
  downloadIcon: {
    borderRadius: '4px',
    fontSize: '12px',
    margin: '0 5px',
  },
  dashboardListPopover: {
    width: '20rem',
    '& .ant-popover-inner-content': {
      padding: '0px',
    },
  },
  pageTitle: {
    marginRight: '1em',
    fontSize: '16px',
    fontWeight: '600',
    marginLeft: '16px',
    color: '#065492',
  },
  breadcrumbItems: (navBarTheme) => ({
    cursor: 'pointer',
    color: navBarTheme['text-color'] ?? undefined,
    '& .ant-dropdown-trigger': {
      color: navBarTheme['text-color'] ?? undefined,
    },
  }),
  breadcrumbItem: (navBarTheme) => ({
    color: navBarTheme['text-color'] ?? undefined,
  }),
});

const routeMetaData = {
  reports: 'Reports',
  dashboard: 'Dashboards',
  visualize: 'Visualize',
  metrics: 'Metrics',
  metric: 'Metric',
  alerts: 'Alerts',
  'scheduled-emails': 'Scheduled Emails',
};

const NavBar = (props) => {
  const { tabsMetaData } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const dashboardGroupName = useSelector(
    (state: any) => state.dashboardGroupMetaData.dashboardGroupName,
    shallowEqual,
  );
  const reportName = useSelector(
    (state: any) => state.reportMetaData.reportName,
  );
  const [selectedTab, setSelectedTab] = useState();
  const [popoverCaretVisible, setPopoverCaretVisible] = useState(false);
  const [dashboardSearchText, setDashboardSearchText] = useState('');
  const [showDashboardList, setShowDashboardList] = useState(false);
  const [breadcrumbsList, setBreadcrumbsList] = useState([]);
  const [pathsList, setpathsList] = useState([]);
  const projectName = getProjectName();
  const analyticsTopBar = useSelector(
    (state: any) => state.config.analyticsTopBar,
  );
  const location = useLocation();
  const navBarTheme = getNavBarTheme();
  const classes = useStyle(navBarTheme);
  const onChangeNavTab = (selectedNavTabs) => {
    dispatch({
      type: CHANGE_NAV_TAB,
      payload: selectedNavTabs,
    });
  };

  const checkDashboardListVisibility = (pathArray) => {
    if (pathArray.length === 2 && pathArray[0] === 'dashboard') {
      return true;
    }
    return false;
  };

  const getBreadCrumbsList = (pathArray, location) => {
    const dashboardListVisibility = checkDashboardListVisibility(pathArray);
    if (dashboardListVisibility) {
      const newBreacrumbsList = [...pathArray];
      newBreacrumbsList[1] = lodashCapitalize(dashboardGroupName);
      return newBreacrumbsList;
    }
    if (pathArray[0] === 'visualize' && pathArray.length === 2) {
      const newBreacrumbsList = [...pathArray];
      newBreacrumbsList[1] = reportName;
      return newBreacrumbsList;
    }
    if (pathArray.length === 2 && pathArray[0] === 'metric') {
      const newBreacrumbsList = [...pathArray];
      newBreacrumbsList[1] = location.state?.name;
      return newBreacrumbsList;
    }
    return pathArray;
  };

  useEffect(() => {
    const pathArray = location.pathname.split('/').filter((e) => e);
    setBreadcrumbsList(getBreadCrumbsList(pathArray, location));
  }, [dashboardGroupName, reportName]);

  useEffect(() => {
    const keys = lodashKeys(tabsMetaData);
    const tabKey = keys.find((key) => {
      return location.pathname === tabsMetaData[key].route;
    });
    setSelectedTab(tabKey);
    const pathArray = location.pathname.split('/').filter((e) => e);
    setpathsList(pathArray);
    setBreadcrumbsList(getBreadCrumbsList(pathArray, location));
    setShowDashboardList(checkDashboardListVisibility(pathArray));
  }, [location.pathname]);

  const urlSearchParams = new URLSearchParams(location.search);
  const getKeyValue =
    urlSearchParams.has('renderedInsideReact') &&
    urlSearchParams.get('renderedInsideReact');
  const isRenderedInsideReactApp = getKeyValue === 'true';
  const tabKeys = lodashKeys(tabsMetaData);

  const onClickBreadCrumb = (index) => {
    const newPathList = pathsList.slice(0, index + 1);
    setpathsList(newPathList);
    setBreadcrumbsList(getBreadCrumbsList(newPathList, location));
    return history.push(`/${newPathList.join('/')}`);
  };

  const onHideDashboardPopOver = () => {
    setPopoverCaretVisible(false);
    setDashboardSearchText('');
  };
  const setPopOverVisibility = (value) => {
    setPopoverCaretVisible(value);
    setDashboardSearchText('');
  };

  return (
    <>
      {!analyticsTopBar && (
        <Affix>
          <div style={{ marginBottom: '1px' }}>
            <div className={classes.basePane}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '50px',
                }}
              >
                <div
                  className={classes.breadCrumbsPane}
                  style={{
                    marginLeft:
                      projectName === projectNames.TRANSPORTERPORTAL ||
                      projectName === projectNames.EXIM
                        ? '0px'
                        : '180px',
                  }}
                >
                  {projectName === projectNames.TRANSPORTERPORTAL ||
                  projectName === projectNames.EXIM ? (
                    <span className={classes.pageTitle}>
                      Business Intelligence
                    </span>
                  ) : null}
                  {projectName !== projectNames.TRANSPORTERPORTAL ?
                    <Breadcrumb className={classes.breadcrumbItems} separator="/">
                      {breadcrumbsList.map((path, index) => {
                        return (
                          <Breadcrumb.Item
                          className={classes.breadcrumbItem}
                            key={index}
                            overlay={
                              index === 0 ? (
                                <DropDownNaigation
                                  onChangeNavTab={onChangeNavTab}
                                />
                              ) : undefined
                            }
                            onClick={() => onClickBreadCrumb(index)}
                          >
                            {routeMetaData[path] || path}
                          </Breadcrumb.Item>
                        );
                      })}
                    </Breadcrumb>
                  : null}
                  {showDashboardList && projectName !== projectNames.TRANSPORTERPORTAL ? (
                    <Popover
                      visible={popoverCaretVisible}
                      onVisibleChange={(v) => setPopOverVisibility(v)}
                      overlayClassName={classes.dashboardListPopover}
                      overlayInnerStyle={{
                        maxHeight: '40rem',
                        overflow: 'scroll',
                        padding: '0 !important',
                      }}
                      content={
                        <NavigationPane
                          searchText={dashboardSearchText}
                          setSearchText={setDashboardSearchText}
                          hidePopover={onHideDashboardPopOver}
                        />
                      }
                      title="Dashboards"
                      trigger="click"
                    >
                      {popoverCaretVisible ? (
                        <CaretDownOutlined
                          style={{
                            marginLeft: '5px',
                            color: navBarTheme['text-color'] ?? undefined,
                          }}
                          onClick={() =>
                            setPopoverCaretVisible(!popoverCaretVisible)
                          }
                        />
                      ) : (
                        <CaretRightOutlined
                          style={{
                            marginLeft: '5px',
                            color: navBarTheme['text-color'] ?? undefined,
                          }}
                          onClick={() =>
                            setPopoverCaretVisible(!popoverCaretVisible)
                          }
                        />
                      )}
                    </Popover>
                  ) : null}
                </div>
              </div>
              {/* <div style={{ marginRight: '16px'}}>
        <Popover
          visible={popoverVisible}
          content={<NavigationPane hidePopover={() => setPopoverVisible(false)}/>} title="Title" trigger="click">
          <Button onClick={() => setPopoverVisible(!popoverVisible)}>Dashboards</Button>
        </Popover>
        </div> */}
            </div>
          </div>
        </Affix>
      )}
    </>
  );
};

export default memo(NavBar);
